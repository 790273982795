import React, { createContext, useContext, useState } from "react";
import { APIURL } from "../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Logout, LoginData } from "../redux/Actions";
import { apilinks } from "../api/APILink";

const AuthContext = createContext();

export const HeaderData = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/Json",
  //   Authorization: `Bearer ${token}`,
};

function Authentication({ children }) {
  const user = useSelector((state) => state.login.user);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  const login = async (data) => {
    const response = await axios.post(
     apilinks.auth.login,
      JSON.stringify({
        username: data.username,
        password: data.password,
      }),
      {
        headers: HeaderData,
      }
    );

    dispatch(LoginData(response.data.responsedata));
    return response;
  };

  // Logout

  const logout = () => {
    const lobject = null;
    const Authentication = false;
    Logout();
  };
  return (
    <AuthContext.Provider value={{ login, logout, isAuthenticated, user }}>
      {children}
    </AuthContext.Provider>
  );
}

export default Authentication;

export const useAuth = () => {
  return useContext(AuthContext);
};
