import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { IconAdd } from "../../themes/Icons";

function Page({ title, handleFunction, buttonname }) {
  const { pathname } = useLocation();

  const condition =
    pathname === "/admin/careers/job-positions" ||
    pathname === "/admin/events/webinars/list" ||
    pathname === "/admin/website/banners" ||
    pathname === "/admin/website/logos" ||
    pathname === "/admin/website/leadership" ||
    pathname === "/admin/website/life-at-ktech" ||
    pathname === "/admin/website/globalfootprints" ||
    pathname === "/admin/website/web-pictures" ||
    pathname === "/admin/events/list" ||
    pathname === "/admin/website/pageicons" ||
    pathname === "/admin/website/industries" ||
    pathname === "/admin/team";

  const handleAddButton = () => {
    handleFunction();
  };


   const Mobile = useMediaQuery((theme) =>
     theme.breakpoints.between("xs", "sm")
   );

   const Tab = useMediaQuery((theme) =>
     theme.breakpoints.between("sm", "md")
   );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%",py:"10px" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{p:"10px"}}
      >
        {/* <Typography variant={Mobile || Tab  ? "subtitle":"h6"}>You are in :</Typography> */}
        <Typography
          variant={Mobile || Tab  ? "subtitle":"h6"}
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack sx={{ pr:"5px" }}>
        {condition ? (
          <Button
            variant="contained"
            startIcon={<IconAdd />}
            onClick={handleAddButton}
          >
            {buttonname}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default Page;
