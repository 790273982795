import {
  Autocomplete,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { RegisterFFields } from "../../../mock/FormFields";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import { ErrorMessage } from "@hookform/error-message";
import { IconPasswordClose, IconPasswordShow } from "../../../themes/Icons";
import { useSnackbar } from "notistack";
import LogoComponent from "../../common/LogoComponent";

const schema = yup.object({
  username: yup.string().required("Email or Employee Id is Required"),
  password: yup.string().required("Password is Required"),
});

const defaultValues = {
  username: "",
  password: "",
};

function LoginForm() {
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------------------------------  Media Queries

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isMobiletoTab = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "md")
  );

  // --------------------------------------------------------------  Media Queries

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleNavigate = () => {
    Navigate("/admin/register");
  };

  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    // console.log("click", data);
    login(data)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status === true) {
          enqueueSnackbar(res.data.message, { variant: "success" });
          Navigate("/admin/home");
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
      sx={{ width: "60%", height: "500px" }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isMobiletoTab ? (
        <Stack
          direction="center"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", pb: 1 }}
        >
          <LogoComponent />
        </Stack>
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Enter your Details to Login
        </Typography>
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
        spacing={2}
      >
        <Stack
          direction="column"
          alignItems="left"
          justifyContent="left"
          sx={{ width: "100%" }}
        >
          <TextField
            label="Email or Employee id"
            fullWidth
            {...register("username")}
            autoComplete="off"
          />
          <ErrorMessage
            errors={errors}
            name="username"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="left"
          justifyContent="left"
          sx={{ width: "100%" }}
        >
          <TextField
            label=" Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            {...register("password")}
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClickShowPassword}
                    sx={{ cursor: "pointer" }}
                  >
                    {showPassword ? (
                      <IconPasswordShow />
                    ) : (
                      <IconPasswordClose />
                    )}
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>
      </Stack>

      <Button variant="contained" type="submit">
        Login
      </Button>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", pt: 1 }}
      >
        <Typography
          variant="body"
          onClick={handleNavigate}
          sx={{
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Don't have an account ? Register Here !
        </Typography>
      </Stack>
    </Stack>
  );
}

export default LoginForm;
