import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
  isLoading: false,
  epositiondata: null,
  isError: null,
};

const slice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    jobopening(state, action) {
      const response = action.payload;
      state.epositiondata = response;
      state.isError = null;

    },
  },
});

export const CareerActions = slice.actions;

export default slice.reducer;
