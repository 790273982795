import React, { Fragment, useEffect, useState } from "react";
import Page from "../../../components/common/Page";
import { useNavigate } from "react-router-dom";
import { DashboardMainContainer } from "../Analytics";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { getWebPictures } from "../../../api/website/WebPictures";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { wpicturesObject } from "../../../redux/slices/website/WebPictures";
import { Domain, TextSection } from "./LifeatKtech";
import { IconDelete, IconEdit } from "../../../themes/Icons";

function WebPictures() {
  const Navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [logos, setlogos] = useState([]);

  const handleNavigate = () => {
    Navigate("/admin/website/web-pictures/create");
  };

  const handleEdit = (item) => {
    dispatch(wpicturesObject(item));
    Navigate("/admin/website/web-pictures/edit");
  };

  useEffect(() => {
    getWebPictures()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        // UpdatedRow(res.data.response);
        if (status === true) {
          // enqueueSnackbar(res.data.message, { variant: "success" });
          setlogos(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
        // enqueueSnackbar(err.data.message, { variant: "error" });
      });
  }, []);

  return (
    <DashboardMainContainer direction="column" alignItems="center">
      <Page
        title="Web Pictures"
        handleFunction={handleNavigate}
        buttonname="Add Web Pictures"
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "90%", py: 2, marginBottom: "20px", height: "auto" }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {logos.length === 0 ? (
          <Typography>No Web Pictures Found !</Typography>
        ) : (
          logos.map((item) => (
            <Card
              item
              key={item.id}
              spacing={1}
              component={Grid}
              // xs={12}
              // md={3.5}
              sx={{
                width: "100%",
                height: "250px",
                // border: `1px solid ${theme.palette.primary.main}`,
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "500px",
                  height: "auto",
                  border: "1px solid lightgray",
                }}
              >
                <img
                  src={`data:image/png;base64,${item.image}`}
                  alt={item.logoname}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
                sx={{ width: { xs: "90%", sm: "90%", md: "60%" } }}
              >
                <TextSection keydata="Image Name" value={item.name} />

                <TextSection keydata="Page Name" value={item.pagename} />

                <TextSection keydata="Page Path" value={item.pagepath} />

                <Domain keydata="Displayed in" value={item.domain} />

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="contained"
                    startIcon={<IconEdit />}
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<IconDelete />}
                    sx={{ bgcolor: "red" }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default  WebPictures
