import React, { useEffect, useState } from "react";
import { DashboardMainContainer } from "../dashboards/Analytics";
import Page from "../../components/common/Page";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Domain, TextSection } from "../dashboards/Website/LifeatKtech";
import { IconDelete, IconEdit } from "../../themes/Icons";
import { getEvents } from "../../api/events/Eventslist";
import { eventsObject } from "../../redux/slices/events/Event";

function Eventslist() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [events, setEvents] = useState([]);

  const handleDateandTime = (date) => {
    const result = "";
    return result;
  };

  const handleNavigate = () => {
    Navigate("/admin/events/create");
  };

  const handleEditData = (item) => {
    dispatch(eventsObject(item));
    Navigate("/admin/events/edit");
  };

  useEffect(() => {
    getEvents()
      .then((res) => {
        console.log(res);
        const status = res.data.success;

        if (status === true) {
          setEvents(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(events);
  return (
    <DashboardMainContainer
      direction="column"
      alignItems="center"
      justifyContent="start"
    >
      <Page
        title="Events"
        handleFunction={handleNavigate}
        buttonname="Add New Event"
      />

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "90%", mt: "10px", mb: "10px" }}
        spacing={2}
      >
        {events.length === 0 ? (
          <Typography variant="h6">No Events Found !</Typography>
        ) : (
          events.map((item) => (
            <Card
              key={item.id}
              sx={{
                width: "auto",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                flexDirection: "column",
                p: 1,
                mb: "10px",
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <CardHeader
                  sx={{ width: "90%", p: 1 }}
                  title={item.eventname}
                  subheader={handleDateandTime(item.eventdateandtime)}
                  action={
                    <IconButton onClick={() => handleEditData(item)}>
                      <IconEdit />
                    </IconButton>
                  }
                />

                <CardMedia
                  sx={{
                    width: "100%",
                    height: "200px",
                    // border: "1px solid blue",
                  }}
                >
                  <img
                    src={`data:image/svg+xml;base64,${item.eventimage}`}
                    alt="Kapil Technologies Event Flyer"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </CardMedia>
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "start",
                    flexDirection: "column",
                    gap: "10px",
                    height: "auto",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSection keydata="Event ID" value={item.eventid} />
                    <TextSection keydata="Event Type" value={item.eventtype} />
                  </Stack>

                  <TextSection keydata="Department" value={item.dept} />
                  <Domain keydata="Domain" value={item.domain} />

                  <TextSection keydata="Description" />
                  <Typography>{item.eventdescription}</Typography>

                  <TextSection keydata="Speakers" />
                  {item.speaker.map((item) => (
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="center"
                      spacing={1}
                      key={item.did}
                    >
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          1
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          .
                        </Typography>
                      </Stack>

                      <TextSection keydata="Name" value={item.employee} />
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        |
                      </Typography>

                      <TextSection keydata="Email" value={item.email} />
                    </Stack>
                  ))}
                  <TextSection keydata="Posted by" value={item.postedby} />
                  <TextSection keydata="Updated by" value={item.updatedby} />
                </CardContent>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default Eventslist;
