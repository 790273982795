import { apilinks } from "./APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createTeam = async (data, token) => {
  const formdata = new FormData();
  formdata.append("empid", data.empid);
  formdata.append("empname", data.empname);
  formdata.append("linkedin", data.linkedin);
  formdata.append("designation", data.designation);
  formdata.append("pagepath", JSON.stringify(data.pagepath));
  formdata.append("picture", data.picture);

  const response = await axios.post(apilinks.employees.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getTeam = async () => {
  const response = await axios.get(apilinks.employees.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateTeam = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
 formdata.append("empid", data.empid);
 formdata.append("empname", data.empname);
 formdata.append("linkedin", data.linkedin);
 formdata.append("designation", data.designation);
 formdata.append("pagepath", JSON.stringify(data.pagepath));
 formdata.append("picture", binaryfile);
  const response = await axios.patch(
    `${apilinks.employees.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE
