import React, { useEffect, useMemo, useState } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Domains, depts, types } from "../../../mock/FormFields";
import { IconEdit } from "../../../themes/Icons";
import { ErrorMessage } from "@hookform/error-message";
import { pagespath } from "../../../mock/Pagespath";
import { addIcon, updateIcon } from "../../../api/website/PageIcons";

const schema = yup.object({
  iconname: yup.string().required("icon Name is Required"),
  icon: yup.mixed().required("icon Required"),
  domain: yup
    .array()
    .min(1, "At least one domain must be selected")
    .required("Domain is Required"),
  pagepath: yup
    .array()
    .min(1, "At least one domain must be selected")
    .required("Page Pa is Required"),
});

function AddIcons() {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [base64, setbase64] = useState(null);

  const [editImage, setEditImage] = useState(false);

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector((state) => state.icons.icondata);
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/pageicons");
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const defaultValues = useMemo(
    () => ({
      iconname: SelObject.iconname || "",
      icon: SelObject.icon || null,
      domain: SelObject.domain || [],
      pagepath: SelObject.pagepath || [],
    }),
    [SelObject]
  );

  const Base64ToBinaryImage = (base64String) => {
    const base64 = `data:image/png;base64,${base64String}`;

    // Split the base64 string to get the actual base64 data
    const base64Data = base64.split(",")[1];

    // Convert the base64 data to a binary string
    const binaryString = window.atob(base64Data);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: "image/png" });

    // Create a URL for the Blob
    return blob;
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  console.log(base64, "icon");

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const EditPic = SelObject.icon;

  const onSubmit = (data) => {
    console.log(data);
    if (paths === "edit") {
      const binaryfile = editImage ? data.icon : Base64ToBinaryImage(EditPic);
      updateIcon(selectedId, data, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });

            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      // console.log("create");
      // console.log(data);
      addIcon(data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };
  return (
    <DashboardMainContainer bgcolorcondition={bgcondition}>
      <Page title={paths !== "edit" ? "Add icon" : "Update icon"} />
      <Grid
        sx={{
          width: "90%",

          p: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "350px" }}
          component={Grid}
          item
          xs={12}
          md={3.5}
          spacing={1}
        >
          {base64 || (EditPic && editImage !== true) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "320px",
                width: "250px",
                objectFit: "cover",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              {editImage || base64 ? (
                <img
                  src={`data:image/png;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              ) : (
                <img
                  src={`data:image/png;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )}
              {EditPic && !base64 ? (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              ) : null}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "300px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              htmlFor="icon"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture !
              </Typography>

              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <input
                    id="icon"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        console.log(base64, "icon");
                        const CleanResume = base64.substring(
                          "data:image/png;base64,".length
                        );

                        setbase64(CleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="icon"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="start"
          spacing={2}
          sx={{ width: "100%", height: "300px" }}
          component={Grid}
          item
          xs={12}
          md={7.5}
        >
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("iconname")}
              label="Icon Name"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="iconname"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="pagepath"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            pagespath.find((c) => `${c.pathname}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.title}`}
                    options={pagespath}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.pathname}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Display Page" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.title}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.pathname}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="pagepath"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="domain"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            Domains.find((c) => `${c.domainvalue}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.domainname}`}
                    options={Domains}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.domainvalue}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Display in" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.domainname}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.domainvalue}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="right"
            sx={{ width: "100%" }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default AddIcons;
