const Main_Server = process.env.REACT_APP_MAIN_SERVER_URL;

const Test_Server = process.env.REACT_APP_TEST_SERVER_URL;

const Local_Server = process.env.REACT_APP_LOCAL_HOST_URL;

// export const APIURL = Local_Server;

export const APIURL = Test_Server;

export const paths = {
  home: "/admin/home",
  team: "/admin/team",
  enquiries: "/admin/enquiries",
  jobopenings: "/admin/careers/job-positions",
  applications: "/admin/careers/applications",
  employeereviews: "/admin/careers/employees-reviews",
  lifeatktech: "/admin/life-at-ktech",
  webinars: "/admin/webinars/list",
  webinaregs: "/admin/webinars/registrations",
  banners: "/admin/website/banners",
  pictures: "/admin/website/pictures",
  logos: "/admin/website/logos",
  mfeedback: "/admin/feedbacks/monthy-feedback",
  testimonial: "/admin/feedbacks/testimonials",
};

// console.log(APIURL)
