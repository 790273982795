import React, { useEffect, useMemo, useState } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Domains, depts, types } from "../../../mock/FormFields";
import { IconEdit } from "../../../themes/Icons";
import { ErrorMessage } from "@hookform/error-message";
import { addLogo, updateLogo } from "../../../api/website/Logos";
import { createBanner, updateBanner } from "../../../api/website/Banners";
import { pagespath } from "../../../mock/Pagespath";

const schema = yup.object({
  name: yup.string().required("Banner Name is Required"),
  pagename: yup.string().required("Page Name is Required"),
  pagepath: yup.string().required("Page Path  is Required"),
  thumbnail: yup.mixed().required("Banner is  Required"),
  domain: yup
    .array()
    .min(1, "At least one domain must be selected")
    .required("Domain is Required"),
});

function CreateBanner() {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [base64, setbase64] = useState(null);

  const [editImage, setEditImage] = useState(false);

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector((state) => state.banners.bannerdata);
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/banners");
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const defaultValues = useMemo(
    () => ({
      name: SelObject.name || "",
      pagename: SelObject.pagename || "",
      pagepath: SelObject.pagepath || "",
      thumbnail: SelObject.banner || null,
      domain: SelObject.domain || [],
    }),
    [SelObject]
  );

  const Base64ToBinaryImage = (base64String) => {
    // Convert base64 string to binary

    const base64 = base64String.substring(base64String.indexOf(",") + 1);

    // Decode the base64 string to a binary string
    const binaryString = window.atob(base64);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a URL for the Blob
    return blob;
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // console.log(base64, "Logo");

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const EditPic = SelObject.banner;

  const onSubmit = (data) => {
    console.log(data);
    if (paths === "edit") {
      const binaryfile = editImage
        ? data.thumbnail
        : Base64ToBinaryImage(EditPic);
      updateBanner(selectedId, data, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      // console.log("create");
      // console.log(data);
      createBanner(data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };
  return (
    <DashboardMainContainer
      direction="column"
      alignItems="center"
      bgcolorcondition={bgcondition}
    >
      <Page title={paths !== "edit" ? "Add Banner" : "Update Banner"} />
      <Grid
        sx={{
          width: "90%",
          // border: "1px solid blue",
          p: 2,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "450px" }}
          component={Grid}
          item
          xs={12}
          md={12}
          spacing={1}
        >
          {base64 || (EditPic && editImage !== true) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "400px",
                width: "auto",
                objectFit: "contain",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              {editImage || base64 ? (
                <img
                  src={`data:image/png;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={`data:image/png;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
              {EditPic && !base64 ? (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              ) : null}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "400px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              htmlFor="pic"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture !
              </Typography>

              <Controller
                name="thumbnail"
                control={control}
                render={({ field }) => (
                  <input
                    id="pic"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        // console.log(base64, "Logo");
                        const CleanResume = base64.substring(
                          "data:image/png;base64,".length
                        );

                        setbase64(CleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="thumbnail"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          spacing={2}
          sx={{ width: "100%", height: "300px" }}
          component={Grid}
          item
          xs={12}
          md={12}
        >
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("name")}
              label="Image Name"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
              <TextField
                {...register("pagename")}
                label="Page Name"
                autoComplete="off"
                fullWidth
              />
              <ErrorMessage
                errors={errors}
                name="pagename"
                render={({ message }) => (
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {message}
                  </Typography>
                )}
              />
            </Stack>{" "}
            <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
              <Controller
                name="pagepath"
                control={control}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <Autocomplete
                      fullWidth
                      value={
                        value
                          ? pagespath.find((option) => {
                              return value == option.pathname;
                            }) ?? null
                          : null
                      }
                      getOptionLabel={(option) => {
                        return option.title;
                      }}
                      options={pagespath}
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.pathname : null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Page Path" />
                      )}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name="pagepath"
                render={({ message }) => (
                  <Typography variant="body2" sx={{ color: "red" }}>
                    {message}
                  </Typography>
                )}
              />
            </Stack>
          </Stack>

          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="domain"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            Domains.find((c) => `${c.domainvalue}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.domainname}`}
                    options={Domains}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.domainvalue}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Display in" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.domainname}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.domainvalue}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="right"
            sx={{ width: "100%" }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateBanner;
