import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const AddCountry = async (data, token) => {
  const formdata = new FormData();
  formdata.append("countryname", data.countryname);
  formdata.append("countrycode", data.countrycode);
  formdata.append("countrydialcode", data.countrydialcode);
  formdata.append("branchavailable", data.branchavailable);
  formdata.append("flag", data.flag);

  const response = await axios.post(apilinks.countries.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getCountries = async () => {
  const response = await axios.get(apilinks.countries.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateCountries = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  console.log(data, "API Page");
  // console.log(data.member)
  // formdata.append("countryname", data.countryname);
  // formdata.append("countrycode", data.countrycode);
  // formdata.append("dailcode", data.countrydialcode);
  formdata.append("branchavailable", data.branch);
  formdata.append("flag", binaryfile);

  const response = await axios.patch(
    `${apilinks.countries.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE

export const deleteLogo = async (id, token) => {
  const response = await axios.delete(`${apilinks.logos.delete}/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
