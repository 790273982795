import { APIURL } from "../config";

export const apilinks = {
  auth: {
    register: `${APIURL}`,
    login: `${APIURL}login`,
    verifyOtp: `${APIURL}`,
    resendoptp: `${APIURL}`,
  },

  openings: {
    add: `${APIURL}create-position `,
    update: `${APIURL}updateposition/:id`,
    get: `${APIURL}getpositions`,
    delete: `${APIURL}deleteposition/:id`,
  },
  applications: {
    update: `${APIURL}updateStatus/:id`,
    get: `${APIURL}getApplications`,
  },
  globalfootprints: {
    add: `${APIURL}globalbranches/create`,
    update: `${APIURL}globalbranches/edit`,
    get: `${APIURL}globalbranches/get`,
    delete: `${APIURL}globalbranches/delete`,
  },
  jobopenings: {
    add: `${APIURL}positions/addposition`,
    update: `${APIURL}positions/editposition/:id`,
    get: `${APIURL}positions/getlist`,
    // delete: `${APIURL}leadership/delete`,
  },
  applications: {
    add: `${APIURL}/create`,
    update: `${APIURL}/edit`,
    get: `${APIURL}get/applications`,
    delete: `${APIURL}/delete`,
  },
  leadership: {
    add: `${APIURL}leadership/create`,
    update: `${APIURL}leadership/edit`,
    get: `${APIURL}leadership/get`,
    delete: `${APIURL}leadership/delete`,
  },
  logos: {
    add: `${APIURL}logos/create`,
    get: `${APIURL}logos/get`,
    update: `${APIURL}logos/edit`,
    delete: `${APIURL}logos/delete`,
  },
  banners: {
    add: `${APIURL}banners/create`,
    get: `${APIURL}banners/get`,
    update: `${APIURL}banners/edit`,
    delete: `${APIURL}banners/delete`,
  },
  webpictures: {
    add: `${APIURL}images/create`,
    get: `${APIURL}images/get`,
    update: `${APIURL}images/edit`,
    delete: `${APIURL}images/delete`,
  },
  countries: {
    add: `${APIURL}countries/create`,
    get: `${APIURL}countries/get`,
    update: `${APIURL}countries/edit`,
    delete: `${APIURL}countries/delete`,
  },
  LAKCS: {
    add: `${APIURL}lifeatktech/create`,
    get: `${APIURL}lifeatktech/get`,
    update: `${APIURL}lifeatktech/edit`,
    delete: `${APIURL}lifeatktech/delete`,
  },
  events: {
    add: `${APIURL}events/create`,
    get: `${APIURL}events/get`,
    update: `${APIURL}events/edit`,
    delete: `${APIURL}events/delete`,
  },
  icons: {
    add: `${APIURL}icons/create`,
    get: `${APIURL}icons/get`,
    update: `${APIURL}icons/edit`,
    delete: `${APIURL}icons/delete`,
  },
  industries: {
    add: `${APIURL}industries/create`,
    get: `${APIURL}industries/get`,
    update: `${APIURL}industries/edit`,
    delete: `${APIURL}industries/delete`,
  },
  employees: {
    add: `${APIURL}employees/create`,
    get: `${APIURL}employees/get`,
    update: `${APIURL}employees/edit`,
    delete: `${APIURL}employees/delete`,
  },
  enquiries: {
    get: `${APIURL}enquiries/get`,
    updatestatus: `${APIURL}enquiries/updatestatus`,
  },
};
