import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const addLogo = async (data, token) => {
  const formdata = new FormData();
  formdata.append("logoname", data.logoname);
  formdata.append("logotype", data.logotype);
  formdata.append("dept", data.dept);
  formdata.append("logo", data.logo);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("pagepath", JSON.stringify(data.pagepath));


  const response = await axios.post(
    apilinks.logos.add,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multimedia/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  GET

export const getLogos = async () => {
  const response = await axios.get(apilinks.logos.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateLogo = async (id, data,binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  formdata.append("logoname", data.logoname);
  formdata.append("logotype", data.logotype);
  formdata.append("dept", data.dept);
  formdata.append("logo", binaryfile);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("pagepath", JSON.stringify(data.pagepath));


  const response = await axios.put(
    `${apilinks.logos.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE

export const deleteLogo = async (id, token) => {
  const response = await axios.delete(
    `${apilinks.logos.delete}/${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
