import React from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";

function EventsRegistrations() {
  return (
    <DashboardMainContainer>
      <Page
        title="Event Registrations"
        // handleFunction={handleNavigate}
        // buttonname="Add New Event"
      />
    </DashboardMainContainer>
  );
}

export default EventsRegistrations;
