import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createMember = async (data, token) => {
  const formdata = new FormData();
  formdata.append("member", data.member);
  formdata.append("empid", data.empid);
  formdata.append("empname", data.empname);
  formdata.append("designation", data.designation);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("serial",data.serial)
  formdata.append("fileformat", data.fileformat)
  formdata.append("linkedinurl", data.linkedinurl);
  formdata.append("subtitle", data.subtitle);

  

  const response = await axios.post(
    apilinks.leadership.add,
    // JSON.stringify({
    //   country: data.country,
    //   companyname: data.companyname,
    //   subtitle: data.subtitle,
    //   add1: data.add1,
    //   add2: data.add2,
    //   city: data.city,
    //   state: data.state,
    //   pincode: data.pincode,
    //   email: data.email,
    //   contact: data.conatct,
    //   addrtype: data.addrtype,
    //   contactperson: data.contactperson,
    //   domain: data.domain,
    // }),
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multimedia/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  GET

export const getMembers = async () => {
  const response = await axios.get(apilinks.leadership.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateMember = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  formdata.append("member", binaryfile);
  formdata.append("empid", data.empid);
  formdata.append("empname", data.empname);
  formdata.append("designation", data.designation);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("serial", data.serial);
  formdata.append("fileformat", data.fileformat);
  formdata.append("linkedinurl", data.linkedinurl);
  formdata.append("subtitle", data.subtitle);



  const response = await axios.patch(
    `${apilinks.leadership.update}/${id}`,
    // JSON.stringify({
    //   country: data.country,
    //   companyname: data.companyname,
    //   subtitle: data.subtitle,
    //   add1: data.add1,
    //   add2: data.add2,
    //   city: data.city,
    //   state: data.state,
    //   pincode: data.pincode,
    //   email: data.email,
    //   contact: data.conatct,
    //   addrtype: data.addrtype,
    //   contactperson: data.contactperson,
    //   domain: data.domain,
    // }),
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multimedia/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE

export const deleteGFP = async (id, token) => {
  const response = await axios.delete(`${apilinks.leadership.delete}/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
