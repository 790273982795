import React, { useEffect, useState } from "react";
import { DashboardMainContainer } from "./Analytics";
import Page from "../../components/common/Page";
import { Card, Dialog, DialogContent, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { getEnquiries } from "../../api/Enquries";
import { useSnackbar } from "notistack";
import DateFormat from "../../components/common/DateFormat";
import { IconEdit } from "../../themes/Icons";
import EnquiryStatus from "../../components/Forms/Status/EnquiryStatus";

const TextSections = ({ keydata, value }) => {
  let textcolor;
  let bcolor;

  if (keydata === "Status") {
    if (value === "raised") {
      textcolor = "white";
      bcolor = "blue";
    } else if (value === "closed") {
      textcolor = "white";
      bcolor = "green";
    } else {
      textcolor = "black";
      bcolor = "transperant";
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
      >
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography
        variant="body1"
        sx={{
          textTransform:
            keydata === "Email"
              ? "none"
              : keydata === "Domain"
              ? "none"
              : "capitalize",
          bgcolor: bcolor,
          p: "2px",
          color: textcolor,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

function Enquiries() {
  const [Enquiries, setEnquiries] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isDesktop = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );

  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );

  const isXLarge = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  useEffect(() => {
    getEnquiries()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        if (status === true) {
          setEnquiries(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);


  const [SelObject, setSelObject] = useState({})
  const [opendialog, setopenDialog] = useState(false);


  
  const handleOpenForm = (selobj) => {
    setopenDialog(true);
    setSelObject(selobj);
  };

   const handleCloseDialog = () => {
     setopenDialog(false);
   };


  return (
    <DashboardMainContainer>
      <Page title="Enquiries" />
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Grid
          sx={{
            p: "10px",
            width: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          container
          columnGap={1}
          rowGap={1}
        >
          {Enquiries.map((item) => (
            <Card
              key={item.id} // Ensure each Card has a unique key
              component={Grid}
              xs={12}
              md={12}
              sx={{
                width: "100%",
                py: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "start",
                height: isMobile || isTab ? "auto" : "auto",
                textAlign: "center",
                p: "10px", // Center the text
                gap: "10px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                  <Typography variant="body1">:</Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.status}
                  </Typography>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => handleOpenForm(item)}
                    sx={{ cursor: "pointer  " }}
                  >
                    <IconEdit />
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Track Id" value={item.trackid} />
                <TextSections keydata="Full Name" value={item.fullname} />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Company" value={item.company} />
                <TextSections keydata="Email" value={item.email} />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Country" value={item.country} />
                <TextSections keydata="Mobile Number" value={item.mobile} />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Industry" value={item.industry} />
                <TextSections keydata="Domain" value={item.domain} />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Message" value={item.message} />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <TextSections keydata="Assigned to" value={item.assignedto} />
                <TextSections
                  keydata="Raised on"
                  value={DateFormat(item.enquiryraisedon)}
                />
              </Stack>
            </Card>
          ))}
        </Grid>
      </Stack>
      <Dialog open={opendialog}>
        <DialogContent sx={{ width: "auto", p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", py: 1 }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Edit Status of Enquiry
            </Typography>
          </Stack>
          <EnquiryStatus Selected={SelObject} closeDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </DashboardMainContainer>
  );
}

export default Enquiries;
