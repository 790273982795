import { APIURL } from "../config";
import axios from "axios";

export const HeaderData = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/Json",
  //   Authorization: `Bearer ${token}`,
};

export const getVisitors = async () => {
  const response = await axios.get(`${APIURL}getVisitors`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

export const Register = async (data) => {
  const response = await axios.post(
    `${APIURL}register`,
    JSON.stringify({
      fullname: data.fullname,
      empid: data.empid,
      email: data.email,
      dept: data.dept,
      reportingmanager: data.reportingmanager,
      mobile: data.mobile,
      password: data.password,
      roleid: data.roleid,
    }),
    {
      headers: HeaderData,
    }
  );
  return response;
};

export const VerifyOTP = async (data) => {
  const response = await axios.post(
    `${APIURL}verifyotp/${data.email}/${data.empid}`,
    JSON.stringify({
      otp: data.otp,
      fullname: data.fullname,
    }),
    {
      headers: HeaderData,
    }
  );
  return response;
};

export const ResendOtp = async (data) => {
  const response = await axios.post(
    `${APIURL}resendotp`,
    JSON.stringify({
      email: data.email,
      empid: data.empid,
      fullname: data.fullname,
    }),
    {
      headers: HeaderData,
    }
  );
  return response;
};

// Changes Password

export const ChangePassword = async (data, token) => {
  const response = await axios.post(
    `${APIURL}resendotp`,
    JSON.stringify({
      currentpassword: data.currentpassword,
      newpassword: data.newpassword,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// forgot Password

// Account Details Changes
