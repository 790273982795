import {
  Avatar,
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import LogoComponent from "./LogoComponent";
import Sidebar from "./Sidebar";
import { SidebarData } from "../../mock/Navigations";
import { IconDot, IconLeftArrow, IconRightArrow } from "../../themes/Icons";
import { useAuth } from "../../auth/Authentication";

export const MainHeader = styled("header")(({ theme, color }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "75px",
  background: theme.palette.primary.main,
  position: "fixed",
  zIndex: 9999,

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },

  // [theme.breakpoints.between("lg", "xl")]: {
  //   // large
  //   width: "100%",
  //   height: 'auto',
  // },
}));

export const LogoContainer = styled(Link)(({ theme, color }) => ({
  textDecoration: "none",
  color: "white",
  padding: "10px",
}));

export const OpenMenu = styled(FiMenu)(({ theme, color }) => ({}));

export const CloseMenu = styled(FiX)(({ theme, color }) => ({}));

export const ProfileIcon = styled(Avatar)(({ theme, color }) => ({
  width: "40px",
  height: "40px",
}));

export const MainNavList = styled("ul")(({ theme, color, toggle }) => ({
  position: "absolute",
  top: "75px",
  zIndex: 9999,
  backgroundColor: theme.palette.primary.main,
  width: "250px",
  height: "100vh",
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  flexDirection: "column",
  overflowY: "scroll",
  left: toggle ? 0 : "-100%",
}));

export const MainNavItem = styled("li")(({ theme, color }) => ({
  listStyle: "none",
  padding: "25px",
  top: "20px",
  position: "relative",
  left: "30px",
}));

export const MainNavLink = styled(NavLink)(({ theme, color }) => ({
  textDecoration: "none",
  color: "white",
}));

export const MainNavText = styled(Box)(({ theme, color }) => ({
  color: "white",
  cursor: "pointer",
}));

export const SubNavItem = styled("li")(({ theme, color }) => ({
  listStyle: "none",
  padding: "10px",
  position: "relative",
  left: -10,
}));

export const SubNavLink = styled(NavLink)(({ theme, color }) => ({
  textDecoration: "none",
  color: "white",
  display: "flex",
  alignItems: "center",
  gap: 2,
}));

function Header({ Toggle, ToggleOpen }) {
  const { user, logout } = useAuth();
  const Navigate = useNavigate();

  // ------------------------------------------------------------- Functions

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleSettings = () => {
    Navigate("/admin/settings");
    handleHide();
  };

  // ------------------------------------------------------------- Media Quires

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isDesktop = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );

  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );

  const isXLarge = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  // ------------------------------------------------------------- Intials

  const getInitials = (fullName) => {
    return fullName
      .split(" ") // Split the full name by spaces
      .map((word) => word[0]) // Map each word to its first letter
      .join(""); // Join the letters together
  };

  // ------------------------------------------------------------- States

  const [toggleMenu, setToggleMenu] = useState(false);

  const [Menuid, setMenuid] = useState(0);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
  };
  const handleCloseToggle = () => {
    setToggleMenu(false);
  };

  const [openMenu, setopenMenu] = useState(false);

  const handleOpenMenu = (id) => {
    setMenuid(id);
    setopenMenu(!openMenu);
  };
  const handlCloseMenu = () => {
    setopenMenu(false);
  };
  return (
    <MainHeader>
      {isMobile || isTab ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            bg: "blue",
            width: "auto",
            height: "auto",
            cursor: "pointer",
            color: "white",
            p: 1,
            fontSize: "30px",
          }}
          onClick={ToggleOpen}
          className="MainNav"
        >
          {Toggle ? <CloseMenu /> : <OpenMenu />}
        </Stack>
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <LogoContainer>
          <LogoComponent />
        </LogoContainer>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ px: 1, color: "regular.color2", position: "relative" }}
      >
        {user && (isMobile || isTab) ? null : user && user.fullname ? (
          <Typography variant="h6">{user.fullname}</Typography>
        ) : null}

        <ProfileIcon
          size="small"
          sx={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          {user && user.fullname ? getInitials(user.fullname) : null}
        </ProfileIcon>
      </Stack>

      {show ? (
        <Stack
          direction="column"
          alignItems="left"
          justifyContent="left"
          spacing={1}
          sx={{
            p: 2,
            width: "auto",
            bgcolor: "white",
            color: "primary.main",
            position: "absolute",
            top: "60px",
            right: "10px",
            borderRadius: "5px",
          }}
        >
          {user !== null ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {user.fullname}
            </Typography>
          ) : null}
          {user !== null ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {user.empid}
            </Typography>
          ) : null}
          {user !== null ? (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {user.email}
            </Typography>
          ) : null}

          <Divider />
          <Typography
            variant="body1"
            onClick={handleSettings}
            sx={{ cursor: "pointer" }}
          >
            Settings
          </Typography>
          <Typography
            variant="body1"
            sx={{ cursor: "pointer" }}
            onClick={logout}
          >
            Logout
          </Typography>
        </Stack>
      ) : null}
    </MainHeader>
  );
}

export default Header;
