import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { UseSelector, useSelector } from "react-redux";
import { ResendOtp, VerifyOTP } from "../../api/Auth";
import Counter from "../../components/common/Counter";

const schema = yup.object({
  otp: yup
    .string()
    .matches(/^[0-9]/, "Must be only digits")
    .test("", "Should be 6 Digits", (value) => {
      // console.log(value);
      return value ? value.length === 6 : null;
    })
    .max(6),
});

function OTP() {
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const userData = useSelector((state) => state.register.ruserdetails);

  // console.log(userData)


  const handleNavigate = () => {
    Navigate("/admin/login");
  };

  const defaultValues = {
    otp: null,
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    // console.log(data);
    data.email = userData.email;
    data.empid = userData.empid;
    data.fullname = userData.fullname
    VerifyOTP(data)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status === true) {
          enqueueSnackbar(res.data.message, { variant: "success" });
          handleNavigate();
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ height: "100vh", bgcolor: "primary.main", width: "100%" }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          width: "400px",
          bgcolor: "secondary.main",
          p: 2,
          height: "auto",
          borderRadius: 2,
          boxShadow: 5,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="h6">Enter the OTP sent to</Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          " {userData.email} "
        </Typography>

        <Stack
          direction="column"
          alignItems="left"
          justifyContent="left"
          spacing={1}
          sx={{ width: "inherit" }}
        >
          <TextField label="OTP" fullWidth {...register("otp")} />
          <ErrorMessage
            errors={errors}
            name="otp"
            render={({ message }) => (
              <Typography
                variant="body2"
                sx={{ color: "red", textTransform: "capitalize" }}
              >
                {message}
              </Typography>
            )}
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", }}
          spacing={1}
        >
          <Counter user={userData} />
        </Stack>
        <Button variant="contained" type="submit">
          Verify OTP
        </Button>
      </Stack>
    </Stack>
  );
}

export default OTP;
