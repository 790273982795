import React, { useEffect, useMemo, useState } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Domains, depts, types } from "../../../mock/FormFields";
import { IconEdit } from "../../../themes/Icons";
import { ErrorMessage } from "@hookform/error-message";
import { addLogo, updateLogo } from "../../../api/website/Logos";
import { AddCountry, updateCountries } from "../../../api/website/Countries";

const branch = [
  {
    id: 1,
    label: "Yes",
    values: "Yes",
  },
  {
    id: 1,
    label: "No",
    values: "No",
  },
];

const schema = yup.object({
  countryname: yup.string().required("Country Name is Required"),
  countrycode: yup.string().required("Country Code is Required"),
  countrydailcode: yup.string().required("Country Dial Code is Required"),
  flag: yup.mixed().required("Flag is Required"),
  branch: yup.string().required("Branch Availability is Required"),
});

function CreateCountry() {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [base64, setbase64] = useState(null);

  const [editImage, setEditImage] = useState(false);

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector((state) => state.countries.countriesData);
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/countries");
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const defaultValues = useMemo(
    () => ({
      countryname: SelObject.countryname || "",
      countrycode: SelObject.countrycode || "",
      countrydailcode: SelObject.countrydailcode || "",
      flag: SelObject.flag || null,
      branch: SelObject.branch || "",
    }),
    [SelObject]
  );

  const Base64ToBinaryImage = (base64) => {
    const base64String = base64.substring(base64.indexOf(",") + 1);

    // Decode the base64 string to a binary string
    const binaryString = window.atob(base64String);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create a URL for the Blob
    return blob;
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // console.log(base64, "Logo");

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const EditPic = SelObject.flag;

  const onSubmit = (data) => {
    // console.log(data);
    if (paths === "edit") {
      console.log(data, " Countries Data");
      // let binaryfile
      const binaryfile =
        editImage || data.flag ? data.flag : Base64ToBinaryImage(EditPic);
      updateCountries(selectedId, data, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });

            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      // console.log("create");
      // console.log(data);
      AddCountry(data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };
  return (
    <DashboardMainContainer bgcolorcondition={bgcondition}>
      <Page title={paths !== "edit" ? "Add Country" : "Update Country"} />
      <Grid
        sx={{
          width: "90%",

          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "350px" }}
          component={Grid}
          item
          xs={12}
          md={3}
          spacing={1}
        >
          {base64 || (EditPic && editImage !== true) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "320px",
                width: "250px",
                objectFit: "cover",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              {editImage || base64 ? (
                <img
                  src={`data:image/svg+xml;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              ) : (
                <img
                  src={`data:image/svg+xml;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )}
              {EditPic && !base64 ? (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              ) : null}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "300px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              htmlFor="pic"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture !
              </Typography>

              <Controller
                name="flag"
                control={control}
                render={({ field }) => (
                  <input
                    id="pic"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        console.log(base64, "Country");
                        const CleanResume = base64.substring(
                          "data:image/svg+xml;base64,".length
                        );

                        setbase64(CleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="flag"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          spacing={2}
          sx={{ width: "100%", height: "300px" }}
          component={Grid}
          item
          xs={12}
          md={7.5}
        >
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("countryname")}
              label="Country Name"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="countryname"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("countrycode")}
              label="Country Code"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="countrycode"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("countrydailcode")}
              label="Country Dail Code"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="countrydailcode"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="branch"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    fullWidth
                    value={
                      value
                        ? branch.find((option) => {
                            return value == option.label;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    options={branch}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.label : null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Branch Available" />
                    )}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="branch"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="right"
            sx={{ width: "100%" }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateCountry;
