import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const addEvent = async (data, token) => {
  const formdata = new FormData();
  formdata.append("eventtype", data.eventtype);
  formdata.append("eventname", data.eventname);
  formdata.append("eventdateandtime", data.eventdateandtime);
  formdata.append("duration", data.duration);
  formdata.append("description", data.description);
  formdata.append("dept", data.dept);
  formdata.append("eventimage", data.eventimage);
  formdata.append("speakers", JSON.stringify(data.speaker));
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("status", "upcoming");
  formdata.append("urllink",data.urllink)

  const response = await axios.post(apilinks.events.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getEvents = async () => {
  const response = await axios.get(apilinks.events.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateEvent = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  //   const formdata = new FormData();
  formdata.append("eventtype", data.eventtype);
  formdata.append("eventname", data.eventname);
  formdata.append("eventdateandtime", data.eventdateandtime);
  formdata.append("duration", data.duration);
  formdata.append("description", data.description);
  formdata.append("dept", data.dept);
  formdata.append("eventimage", binaryfile);
  formdata.append("speakers", JSON.stringify(data.speakers));
  formdata.append("domain", JSON.stringify(data.domain));

  const response = await axios.put(
    `${apilinks.events.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE
