import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  enqiryStatus: null,
  isError: null,
};

const slice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    enquirystatus(state, action) {
      const response = action.payload;
      state.enqiryStatus = response;
      state.isError = null;
    },
  },
});

export const  EnquiryActions = slice.actions;

export default slice.reducer;
