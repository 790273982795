import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  countriesData: null,
  isError: null,
};

const slice = createSlice({
  name: "events",
  initialState,
  reducers: {
    eventsObject(state, action) {
      const response = action.payload;
      state.eventsdata = response;
      state.isError = null;
    },
  },
});

export const { eventsObject } = slice.actions;

export default slice.reducer;
