import {
  Autocomplete,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  IconAdd,
  IconDelete,
  IconEdit,
  IconRightArrow,
} from "../../../themes/Icons";
import { EditPosition, createPosition } from "../../../api/Careers";
import {
  Domains,
  countrys,
  depts,
  formOptions1,
  formOptions2,
  statusdata,
} from "../../../mock/FormFields";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../auth/Authentication";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector, useDispatch } from "react-redux";
import { JobData } from "../../../redux/Actions";

const schema = yup.object({
  domain: yup
    .array()
    .of(yup.string().required("Each domain must be a valid string"))
    .required("Domain is required")
    .min(1, "At least one domain must be provided"),
  jobtitle: yup.string().required("Jobtitle is Required"),
  dept: yup.string(),
  jobtype: yup.string().required("Job Type is Required"),
  duration: yup.string(),
  experience: yup.string().required("Experience is Required"),
  jobmode: yup.string().required("Job Mode is Required"),
  location: yup.string(),
  description: yup
    .array()
    .required("Description is required")
    .min(1, "At least 1 items is required")
    .max(10, "You are limited to a maximum of 10 items"),
  timezone: yup.string(),
  emailforprofiles: yup
    .string()
    .required("Email is Required")
    .email("Invalid Email ID"),
});

function CreatePosition() {
  const { user } = useAuth();
  const token = user === null ? null : user.accesstoken;
  const { pathname } = useLocation();
  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const selecteddata = useSelector((state) => state.careers.epositiondata);
  const SelObject = paths === "create" ? {} : selecteddata;

  console.log(SelObject);

  // console.log(object)

  const dispatch = useDispatch();

  // ----------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      domain: SelObject.domain || [],
      jobtitle: SelObject.jobtitle || "",
      dept: SelObject.category || "",
      jobtype: SelObject.jobtype || "",
      duration: SelObject.duration || "",
      experience: SelObject.experience || "",
      jobmode: SelObject.jobmode || "",
      location: SelObject.location || "",
      description: SelObject.description || [{ did: 1, ddata: "" }],
      timezone: SelObject.timezone || "",
      emailforprofiles: SelObject.postedbyemail || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [SelObject]
  );

  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/admin/careers/job-positions");
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: "description",
  });

  const values = watch();

  console.log(values.status);

  const onSubmit = (data) => {
    // console.log(data);

    const Selid = SelObject.jobid;
    // console.log(Selid);

    if (paths === "edit") {
      // console.log(data,"DATA")
      EditPosition(Selid, data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;

          // UpdatedRow(res.data.response);
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
          // enqueueSnackbar(err.data.message, { variant: "error" });
        });
    } else {
      createPosition(data, token)
        // dispatch(JobData(data))
        .then((res) => {
          // console.log(res);
          const status = res.data.success;
          const Inserteddata = res.data.response;
          // UpdatedRow(res.data.response);
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
          // enqueueSnackbar(err.data.message, { variant: "error" });
        });
    }
  };

  return (
    <DashboardMainContainer bgcolorcondition={bgcondition}>
      <Page title={paths === "edit" ? "Update Position" : "Create Position"} />
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
          sx={{ width: "90%" }}
        >
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="domain"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            Domains.find((c) => `${c.domainvalue}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.domainname}`}
                    options={Domains}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.domainvalue}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Domain" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.domainname}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.domainvalue}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />

            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              {...register("jobtitle")}
              label="Job Title"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="jobtitle"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="dept"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    fullWidth
                    value={
                      value
                        ? depts.find((option) => {
                            return value == option.label;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    options={depts}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.label : null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Department" />
                    )}
                  />
                );
              }}
            />

            <ErrorMessage
              errors={errors}
              name="dept"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
          sx={{ width: "90%" }}
        >
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="jobtype"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    fullWidth
                    value={
                      value
                        ? formOptions1.find((option) => {
                            return value == option.label;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    options={formOptions1}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.label : null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Job Type" />
                    )}
                  />
                );
              }}
            />

            <ErrorMessage
              errors={errors}
              name="jobtype"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              {...register("duration")}
              label="Duration"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="duration"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              {...register("experience")}
              label="Experience"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="experience"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
          sx={{ width: "90%" }}
        >
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="jobmode"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    fullWidth
                    value={
                      value
                        ? formOptions2.find((option) => {
                            return value == option.label;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    options={formOptions2}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.label : null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Job Mode" />
                    )}
                  />
                );
              }}
            />

            <ErrorMessage
              errors={errors}
              name="jobtype"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              {...register("location")}
              label="Location"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="location"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              {...register("emailforprofiles")}
              label="Email for Profiles"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="emailforprofiles"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="start"
          spacing={1}
          sx={{ width: "90%" }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            sx={{ width: "95%" }}
            spacing={2}
          >
            {fields.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Typography variant="body1">{item.did}.</Typography>
                <Controller
                  name={`description.${index}.ddata`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Description"
                      size="small"
                      autoComplete="off"
                      {...field}
                      error={!!errors.description?.[index]?.ddata}
                      helperText={errors.description?.[index]?.ddata?.message}
                    />
                  )}
                />

                <Button
                  variant="contained"
                  onClick={() => {
                    remove(index);
                  }}
                  sx={{ p: 1 }}
                >
                  Delete
                </Button>
              </Stack>
            ))}
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ pt: 0 }}
          >
            <Button
              variant="contained"
              sx={{ p: 1 }}
              onClick={() => {
                append({
                  did: fields.length + 1,
                  ddata: "",
                });
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="right"
          spacing={2}
          sx={{ width: "90%" }}
        >
          <Button variant="contained" type="submit" startIcon={<IconAdd />}>
            {paths === "edit" ? "Save" : "Create Position"}
          </Button>
        </Stack>
      </Stack>
    </DashboardMainContainer>
  );
}

export default CreatePosition;
