import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  icondata: null,
  isError: null,
};

const slice = createSlice({
  name: "ktechicons",
  initialState,
  reducers: {
    iconsObject(state, action) {
      const response = action.payload;
      state.icondata = response;
      state.isError = null;
    },
  },
});

export const { iconsObject } = slice.actions;

export default slice.reducer;
