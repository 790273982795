import React, { Suspense, createContext, useContext, useState } from "react";
import "./App.css";
import { CustomTheme } from "./themes/Theme";
import { ThemeProvider, styled } from "@mui/material/styles";
import Footer from "./components/common/Footer";
import Sidebar from "./components/common/Sidebar";
import Header from "./components/common/Header";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import Authentication from "./auth/Authentication";
import { SnackbarProvider } from "notistack";
import AdminRoutes from "./router/AdminRoutes";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const ToggleContext = createContext(null);

function App() {
  const { pathname } = useLocation();

  const Panels = pathname.split("/");

  const [toggleData, settoggleData] = useState(false);

  const handleToggle = () => {
    settoggleData(!toggleData);
  };

  const handleCloseToggle = () => {
    settoggleData(false);
  };

  

  // console.log(Condition);

  const condition =
    pathname === "/admin/login" ||
    pathname === "/admin/register" ||
    pathname === "/admin/otp" ||
    pathname === "/404" ||
    pathname === "/admin/404";

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={CustomTheme}>
            <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
              <Authentication>
                <Stack
                  direction="row"
                  alignItems="start"
                  justifyContent="left"
                  sx={{
                    width: "100%",
                    height: "100vh",
                    position: "relative",
                  }}
                >
                  {condition ? null : (
                    <Header
                      Toggle={toggleData}
                      ToggleOpen={handleToggle}
                      ToggleClose={handleCloseToggle}
                    />
                  )}

                  <Stack
                    direction="column"
                    alignItems="left"
                    justifyContent="left"
                    sx={{
                      height: condition ? "100%" : "calc(100% - 75px)",
                      width: "100%",
                    }}
                  >
                    {condition ? null : (
                      <Sidebar
                        Toggle={toggleData}
                        ToggleClose={handleCloseToggle}
                      />
                    )}
                    <ToggleContext.Provider value={toggleData}>
                      <AdminRoutes />
                    </ToggleContext.Provider>
                  </Stack>
                </Stack>
              </Authentication>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
