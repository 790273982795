export const Tabs1 = [
  {
    id: 1,
    title: "Domain Visits",
    value: "1",
  },
  {
    id: 2,
    title: "Domain Visits in Country",
    value: "2",
  },
  {
    id: 3,
    title: "Page Visits as Per Domain",
    value: "3",
  },
  {
    id: 4,
    title: "Page Visits as Per Domain (Country)",
    value: "4",
  },
  {
    id: 5,
    title: "Particular Page Visit",
    value: "5",
  },
  {
    id: 6,
    title: "Jobs Visits",
    value: "6",
  },
  {
    id: 7,
    title: "Devices",
    value: "7",
  },
];