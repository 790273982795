import React, { Fragment } from "react";
import Page from "../../../components/common/Page";

function Testimonials() {
  return (
    <Fragment>
      <Page title="Testimonials" />
    </Fragment>
  );
}

export default Testimonials;
