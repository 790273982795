import { combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "./slices/Login";
import RegisterReducer from "./slices/Register";
// import WebinarReducer from "./slices/Webinars";
import CareersReducer from "./slices/Careers";
import GlobalFPReducer from "./slices/website/GlobalFootPrints";
import BannersReducer from "./slices/website/Banners";
import LifeatKtechReducer from "./slices/website/LIfeatKtech";
import LogosReducers from "./slices/website/Logos";
import IconsReducers from "./slices/website/Icons";
import EnquiryReducer from "./slices/Enquiry";

import LeadershipReducer from "./slices/website/Management";
import WebPReducer from "./slices/website/WebPictures";
import CountriesReducer from "./slices/website/Countries";
import EventsReducer from "./slices/events/Event";
import EmployeesReducer from "./slices/Employees";
import IndustriesReducer from "./slices/website/Industries";

export const rootReducers = combineReducers({
  login: LoginReducer,
  register: RegisterReducer,
  events: EventsReducer,
  careers: CareersReducer,
  globalfootprints: GlobalFPReducer,
  banners: BannersReducer,
  lifeatktech: LifeatKtechReducer,
  ktechLogos: LogosReducers,
  leadership: LeadershipReducer,
  webpictures: WebPReducer,
  countries: CountriesReducer,
  icons: IconsReducers,
  employees: EmployeesReducer,
  industries: IndustriesReducer,
  enquiry:EnquiryReducer
});
