import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createIndustry = async (data, token) => {
  const formdata = new FormData();
  formdata.append("industryname", data.industryname);
  formdata.append("indusimage", data.indusimage);
  formdata.append("colorname", data.colorname);
  formdata.append("pagepath", JSON.stringify(data.pagepath));

  const response = await axios.post(apilinks.industries.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getIndustries = async () => {
  const response = await axios.get(apilinks.industries.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateIndustries = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  formdata.append("industryname", data.industryname);
  formdata.append("indusimage", binaryfile);
  formdata.append("colorname", data.colorname);
  formdata.append("pagepath", JSON.stringify(data.pagepath));

  const response = await axios.patch(
    `${apilinks.industries.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE
