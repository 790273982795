import React, { Fragment, useEffect, useState } from "react";
import Page from "../../../components/common/Page";
import { useNavigate } from "react-router-dom";
import { DashboardMainContainer } from "../Analytics";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logoObject } from "../../../redux/slices/website/Logos";
import { IconDelete, IconEdit } from "../../../themes/Icons";
import { getLogos } from "../../../api/website/Logos";
import { useSnackbar } from "notistack";
import { Domain, TextSection } from "./LifeatKtech";

function Logos() {
  const Navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [logos, setlogos] = useState([]);

  const handleNavigate = () => {
    Navigate("/admin/website/logos/create");
  };

  const handleEdit = (item) => {
    dispatch(logoObject(item));
    Navigate("/admin/website/logos/edit");
  };

  useEffect(() => {
    getLogos()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        // UpdatedRow(res.data.response);
        if (status === true) {
          // enqueueSnackbar(res.data.message, { variant: "success" });
          setlogos(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
        // enqueueSnackbar(err.data.message, { variant: "error" });
      });
  }, []);

  return (
    <DashboardMainContainer>
      <Page
        title="Logos"
        handleFunction={handleNavigate}
        buttonname="Add New Logo"
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "100%", py: 2, marginBottom: "20px", height: "auto" }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {logos.length === 0 ? (
          <Typography>No logos Found !</Typography>
        ) : (
          logos.map((item) => (
            <Card
              item
              key={item.id}
              spacing={1}
              component={Grid}
              xs={12}
              md={5.5}
              sx={{
                height: "300px",
                // border: `1px solid ${theme.palette.primary.main}`,
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                gap: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "250px",
                  height: "250px",
                  border: "1px solid lightgray",
                }}
              >
                <img
                  src={`data:image/png;base64,${item.logo}`}
                  alt={item.logoname}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "80%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="left"
                justifyContent="space-between"
                sx={{ height: "90%", width: "60%" }}
              >
                <Stack
                  direction="column"
                  alignItems="left"
                  justifyContent="left"
                  spacing={1}
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  <TextSection keydata="Logo Name" value={item.logoname} />
                  <TextSection keydata="Logo Typpe" value={item.logotype} />
                  <TextSection keydata="Department" value={item.dept} />
                  <Domain keydata="Domain" value={item.domain} />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="contained"
                    startIcon={<IconEdit />}
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<IconDelete />}
                    sx={{ bgcolor: "red" }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default Logos;
