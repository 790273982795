import React, { useEffect, useMemo } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Domains } from "../../../mock/FormFields";
import {
  createBranch,
  updateGFP,
} from "../../../api/website/GlobalBranchesAPI";
import { useSelector, useDispatch } from "react-redux";
import { branchobject } from "../../../redux/slices/website/GlobalFootPrints";

const schema = yup.object({
  country: yup.string().required("Country is Required"),
  companyname: yup.string().required("Company Name is Required"),
  subtitletype: yup.string(),
  subtitle: yup.string(),
  add1: yup.string().required("Address 1 is Required"),
  add2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  pincode: yup.string().required("Pincode is Required"),
  email: yup
    .string()
    .required("Email  is Required")
    .email("Invalid Email Format"),
  contact: yup.string(),
  addrtype: yup.string().required("Address Type is Required"),
  contactperson: yup.string(),
  domain: yup.array().required("Domain is Required"),
});

const Addtype = [
  {
    id: 1,
    value: "registered",
    label: "Registered",
  },
  {
    id: 2,
    value: "non-registered",
    label: "Non Registered",
  },
];

const SubtitleType = [
  {
    id: 1,
    value: "formerly",
    label: "Formerly",
  },
  {
    id: 2,
    value: "careof",
    label: "C/O",
  },
];

function CreateGbranch() {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector(
    (state) => state.globalfootprints.branchdetails
  );
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/globalfootprints");
  };

  const defaultValues = useMemo(
    () => ({
      country: SelObject.country || "",
      companyname: SelObject.companyname || "",
      subtitletype: SelObject.subtitletype || "",
      subtitle: SelObject.subtitle || "",
      add1: SelObject.add1 || "",
      add2: SelObject.add2 || "",
      city: SelObject.city || "",
      state: SelObject.state || "",
      pincode: SelObject.zipcode || "",
      email: SelObject.contactemail || "",
      contact: SelObject.contact || "",
      addrtype: SelObject.addrtype || "",
      contactperson: SelObject.contactperson || "",
      domain: SelObject.showin || [],
    }),
    [SelObject]
  );

  const { register, handleSubmit, reset, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const onSubmit = (data) => {
    console.log(data);

    if (paths === "edit") {
      console.log("edit");
      updateGFP(selectedId, token, data)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });

            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      console.log("create");
      createBranch(data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            const newBranch = res.data.response;
            dispatch(branchobject(newBranch));
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };

  return (
    <DashboardMainContainer
      bgcolorcondition={bgcondition}
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Page title={paths !== "edit" ? "Add New Branch" : "Update Branch"} />

      <Grid
        sx={{
          width: "90%",
          // border: "1px solid blue",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("country")}
            label="Country"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("companyname")}
            label="Registered Company Name"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <Controller
            name="subtitletype"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  fullWidth
                  value={
                    value
                      ? SubtitleType.find((option) => {
                          return value == option.value;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  options={SubtitleType}
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.value : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Subtitle Type" />
                  )}
                />
              );
            }}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("subtitle")}
            label="C/o (or) Subtitle"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("add1")}
            label="Address Line 1"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("add2")}
            label="Address Line 2"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <Controller
            name="addrtype"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  fullWidth
                  value={
                    value
                      ? Addtype.find((option) => {
                          return value == option.value;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  options={Addtype}
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.value : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Address Type" />
                  )}
                />
              );
            }}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField {...register("city")} label="City" autoComplete="off" />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField {...register("state")} label="State" autoComplete="off" />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("pincode")}
            label="Zip Code"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("email")}
            label="Contact Email"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("contact")}
            label="Contact Number"
            autoComplete="off"
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <TextField
            {...register("contactperson")}
            label="Contact Person"
            autoComplete="off"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={5.5}
        >
          <Controller
            name="domain"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  multiple
                  fullWidth
                  value={
                    value
                      ? value.map((v) =>
                          Domains.find((c) => `${c.domainvalue}` === v)
                        )
                      : []
                  }
                  getOptionLabel={(option) => `${option.domainname}`}
                  options={Domains}
                  onChange={(e, newValue) => {
                    const selectedValues = newValue.map(
                      (v) => `${v.domainvalue}`
                    );
                    onChange(selectedValues);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Display in" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={`${option.domainname}`}
                        {...getTagProps({ index })}
                        onDelete={() => {
                          const newValues = [...value];
                          newValues.splice(index, 1);
                          onChange(newValues.map((v) => `${v.domainvalue}`));
                        }}
                      />
                    ))
                  }
                />
              );
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="right"
          sx={{ width: "100%" }}
          component={Grid}
          item
          xs={12}
          md={11}
        >
          <Button variant="contained" type="submit" sx={{ width: "100px" }}>
            Submit
          </Button>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateGbranch;
