import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createImage = async (data, token) => {
  const formdata = new FormData();
  formdata.append("eventname", data.eventname);
  formdata.append("thumbnail", data.thumbnail);
  formdata.append("displayimage", data.show);
  formdata.append("domain", JSON.stringify(data.domain));

  const response = await axios.post(apilinks.LAKCS.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getKtechImages = async () => {
  const response = await axios.get(apilinks.LAKCS.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateImage = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
    formdata.append("eventname", data.eventname);
    formdata.append("thumbnail",binaryfile);
    formdata.append("displayimage", data.show);
    formdata.append("domain", JSON.stringify(data.domain));

  const response = await axios.put(`${apilinks.LAKCS.update}/${id}`, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  DELETE

export const deleteGFP = async (id, token) => {
  const response = await axios.delete(
    `${apilinks.globalfootprints.get}/${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
