import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { RegisterFFields, countrys } from "../../../mock/FormFields";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { IconPasswordClose, IconPasswordShow } from "../../../themes/Icons";
import { Register } from "../../../api/Auth";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { RegisterData, getRegisteredUser } from "../../../redux/Actions";
import LogoComponent from "../../common/LogoComponent";

const formOptions = [
  {
    id: 1,
    value: 0,
    label: "All",
  },
  {
    id: 2,
    value: 1,
    label: "HR",
  },
  {
    id: 3,
    value: 2,
    label: "Pre-Sales",
  },
  {
    id: 3,
    value: 3,
    label: "Sales",
  },
  {
    id: 4,
    value: 4,
    label: "Marketing",
  },
];

const formOptions1 = [
  {
    id: 1,
    value: 0,
    label: "All",
  },
  {
    id: 2,
    value: 1,
    label: "HR",
  },
  {
    id: 3,
    value: 2,
    label: "Pre-Sales",
  },
  {
    id: 4,
    value: 3,
    label: "Sales",
  },
  {
    id: 5,
    value: 4,
    label: "Marketing",
  },
  {
    id: 6,
    value: 5,
    label: "HR and Marketing",
  },

  {
    id: 7,
    value: 6,
    label: "HR and Sales",
  },

];

const ReportingManagers = [
  {
    id: 1,
    empid: "KCSH871105",
    empname: "Prem Kumar Dasari",
  },
  {
    id: 2,
    empid: "KCSH281267",
    empname: "Lalitha Lanka",
  },
  {
    id: 3,
    empid: "KCSH281275",
    empname: "Krishnan B",
  },
  {
    id: 4,
    empid: "KCSH571120",
    empname: "Santhiya Nair",
  },
  {
    id: 5,
    empid: "KCSH281266",
    empname: "Swapnil Bajpai",
  },
  {
    id: 6,
    empid: "KCSH281273",
    empname: "Suraksha Kanuru",
  },
  {
    id: 7,
    empid: "KCSH281257",
    empname: "Ritesh Singh",
  },
];

const kcsregix = "^[a-zA-Z0-9._%+-]+@kcs-tech.com$";

const schema = yup.object({
  fullname: yup.string().required("Full Name is Required"),
  empid: yup.string().required("Employee ID is Required"),
  dept: yup.string().required("Department is Required"),
  rmanager: yup.string().required("Reporting Manager is Required"),
  cemail: yup.string().email("Invalid Email").required("Email is Required"),
  ccode: yup.string().required("Country Code is Required"),
  mobile: yup.string().required("Mobile Number is Required"),
  password: yup.string().required("Password is Required"),
  cpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required"),
});

const defaultValues = {
  fullname: "",
  empid: "",
  dept: "",
  rmanagerid: "",
  cemail: "",
  ccode: "",
  mobile: "",
  password: "",
  cpassword: "",
};

function RegisterForm() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();

  // --------------------------------------------------------------  Media Queries

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isMobiletoTab = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "md")
  );

  // --------------------------------------------------------------  Media Queries

  const [roleid, setRoleid] = useState(0);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleNavigate = (email) => {
    Navigate("/admin/otp");
  };

  const handleLogin = () => {
    Navigate("/admin/login");
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    // console.log("click", data, roleid);
    const mobilenumber = `${data.ccode}-${data.mobile}`;
    const reqdata = {
      fullname: data.fullname,
      empid: data.empid,
      email: data.cemail,
      dept: data.dept,
      reportingmanager: data.rmanager,
      mobile: mobilenumber,
      password: data.password,
      roleid: roleid,
    };
    // console.log(reqdata);
    dispatch(RegisterData(reqdata));
    Register(reqdata)
    .then((res) => {
      // console.log(res);
      const status = res.data.success;
      const statusCode = res.data.message;
      if (status === true) {
        enqueueSnackbar(res.data.message, { variant: "success" });
        handleNavigate();
      } else if (statusCode === "User not Verified !") {
        enqueueSnackbar(res.data.message, { variant: "error" });
        handleNavigate();
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    })
    .catch((err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    });
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      spacing={1}
      sx={{ width: "80%", height: "500px" }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isMobiletoTab ? (
        <Stack
          direction="center"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", pb: 1 }}
        >
          <LogoComponent />
        </Stack>
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Enter your Details to Register
        </Typography>
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
        spacing={2}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Employee ID"
              fullWidth
              {...register("empid")}
              autoComplete="false"
            />
            <ErrorMessage
              errors={errors}
              name="empid"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Full Name"
              fullWidth
              {...register("fullname")}
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="fullname"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="dept"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={
                      value
                        ? formOptions.find((option) => {
                            return value == option.label;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    options={formOptions}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.label : null);
                      setRoleid(newValue.value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Department" />
                    )}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="dept"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="rmanager"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={
                      value
                        ? ReportingManagers.find((option) => {
                            return value == `${option.empid}-${option.empname}`;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return `${option.empname}`;
                    }}
                    options={ReportingManagers}
                    onChange={(e, newValue) => {
                      onChange(
                        newValue
                          ? `${newValue.empid}-${newValue.empname}`
                          : null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Reporting Manager" />
                    )}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="rmanager"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Email"
              fullWidth
              {...register("cemail")}
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="cemail"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Controller
              name="ccode"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    sx={{ width: "100%" }}
                    value={
                      value
                        ? countrys.find((option) => {
                            return value == option.dail;
                          }) ?? null
                        : null
                    }
                    getOptionLabel={(option) => {
                      return `${option.id}(${option.dail})`;
                    }}
                    options={countrys}
                    onChange={(e, newValue) => {
                      onChange(newValue ? newValue.dail : null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Country Code" />
                    )}
                  />
                );
              }}
            />

            <ErrorMessage
              errors={errors}
              name="ccode"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Mobile"
              fullWidth
              {...register("mobile")}
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="mobile"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              {...register("password")}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleClickShowPassword}
                      sx={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <IconPasswordShow />
                      ) : (
                        <IconPasswordClose />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="left"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              label="Confirm Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              {...register("cpassword")}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleClickShowPassword}
                      sx={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <IconPasswordShow />
                      ) : (
                        <IconPasswordClose />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage
              errors={errors}
              name="cpassword"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Button variant="contained" type="submit">
        Register
      </Button>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Typography
          variant="body"
          onClick={handleLogin}
          sx={{
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Already have an account ?
        </Typography>
      </Stack>
    </Stack>
  );
}

export default RegisterForm;
