import { apilinks } from "../api/APILink";
import axios from "axios";
import { APIURL } from "../config";

export const getEnquiries = async () => {
  const response = await axios.get(apilinks.enquiries.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

export const updateEnquiryStatus = async (id, data, token) => {
  const response = await axios.put(
    `${APIURL}enquiries/updatestatus/${id}`,
    JSON.stringify({
      status: data.status,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
