import React, { Fragment, useEffect, useState } from "react";
import { DashboardMainContainer } from "../Analytics";
import Page from "../../../components/common/Page";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  IconAdd,
  IconClose,
  IconDelete,
  IconDot,
  IconDownArrow,
  IconEdit,
} from "../../../themes/Icons";
import CreatePosition from "../../../components/Forms/careers/CreatePositions";
import {
  EditPosition,
  deletePositions,
  getApplications,
  getPositions,
  getPositonStats,
  updatePositionStatus,
} from "../../../api/Careers";
import { enqueueSnackbar } from "notistack";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuth } from "../../../auth/Authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { JobData } from "../../../redux/Actions";
import { useDispatch } from "react-redux";
import PositionStatus from "../../../components/Forms/careers/PositionStatus";

const schema = yup.object({
  satus: yup.string(),
});

const status = [
  {
    id: 1,
    value: "active",
    label: "Active",
  },
  {
    id: 2,
    value: "closed",
    label: "Closed",
  },
];

const MainContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  backgroundColor: theme.palette.terinary.main,

  [theme.breakpoints.between("xs", "sm")]: {
    //  mobile
  },

  [theme.breakpoints.between("sm", "md")]: {
    // tab
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },

  [theme.breakpoints.up("xl")]: {},
}));

export const TextSections = ({ keydata, value }) => {
  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
      >
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
        {value}
      </Typography>
    </Stack>
  );
};

export const TextSections2 = ({ keydata, value }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", textTransform: "capitalize" }}
      >
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  );
};

function OpenPositions() {
  const { user } = useAuth();
  const Navigate = useNavigate();
  const token = user === null ? null : user.accesstoken;
  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const Tab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  // console.log(token);
  // ----------------------------------------------------------------------------

  const [opendialog, setopenDialog] = useState(false);
  const [deleteDialog, setdeleteDialog] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [openings, setopenings] = React.useState([]);
  const [selectedid, setselectedid] = React.useState(0);
  const [selectedObject, setSelectedObject] = useState({});
  const [postionObject, setPostionObject] = useState({});
  const [dbid, setdbid] = useState(0);
  const [statusEdit, setStatusEdit] = useState(false);

  const handleChange = (panel, jobid) => (event, isExpanded) => {
    console.log(panel, jobid);
    setExpanded(isExpanded ? panel : false);
    // getPositonStats(jobid)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleOpenForm = () => {
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const handleOpenDelete = (id) => {
    console.log(id);
    setdeleteDialog(true);
    setselectedid(id);
  };

  const handleCloseDelete = () => {
    setdeleteDialog(false);
  };

  const handleUpdateRow = (object) => {
    setPostionObject(object);
  };

  const { pathname } = useLocation();
  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";

  // ---------------------------------------------------  Get Positions

  const dispatch = useDispatch();

  useEffect(() => {
    getPositions(token)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status === true) {
          setopenings(res.data.response);
          // enqueueSnackbar(res.data.message, { variant: "success" });
          handleCloseDialog();
        } else {
          // enqueueSnackbar(res.data.message, { variant: "error" });
          setopenings(null);
        }
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar(err, { variant: "error" });
      });
  }, []);

  useEffect(() => {
    getApplications(token)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar(err, { variant: "error" });
      });
  }, []);

  // ---------------------------------------------------  Edit Positions

  const handleEdit = (selobj) => {
    // handleOpenForm();
    // console.log(selobj.id);
    // setselectedid(selobj.id);
    // setSelectedObject(selobj);
    dispatch(JobData(selobj));
    Navigate("/admin/careers/job-positions/edit");
  };

  // ---------------------------------------------------  Delete Positions

  const handleDelete = () => {
    console.log(selectedid);
    deletePositions(selectedid, token)
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        if (status === true) {
          enqueueSnackbar(res.data.message, { variant: "success" });

          handleCloseDelete();
        } else enqueueSnackbar(res.data.message, { variant: "error" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err, { variant: "error" });
      });
  };

  // --------------------------------------------------------------------------------

  const handleDate = (date) => {
    const nd = new Date(date);
    const AppliedDate = nd.toDateString();
    return AppliedDate;
  };

  const defaultValues = {
    status: "",
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleStatusUpdate = (id) => {
    setdbid(id);
    setStatusEdit(!statusEdit);
  };

  const handleStatusUpdateClose = () => {
    setStatusEdit(false);
  };

  const onSubmit = (data) => {
    // console.log(data);
    updatePositionStatus(dbid, data, token)
      .then((res) => {
        const status = res.data.success;
        if (status) {
          enqueueSnackbar(res.data.message, { variant: "success" });
          handleStatusUpdateClose();
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const [jobid, setjobid] = useState("");

  const handlePostionStats = (id) => {
    // console.log(id);
    getPositonStats(id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getPositonStats(jobid)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // },[]),

  // useEffect(() => {
  //   getPositonStats(jobid)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const handleDomain = (domain) => {
    const countries = domain.map((item) => item);
    // console.log(countries);
    const countriesString = countries.join(", ");
    return countriesString;
  };

  const handleNavigate = () => {
    Navigate("/admin/careers/job-positions/create");
  };

  const [openstatusdialog, setopenstatusdialog] = useState(false);

  const [statusObject, setStatusObject] = useState({});

  const handleEditform = (data) => {
    setStatusObject(data);
    setopenstatusdialog(true);
  };

  const handleCloseEditForm = () => {
    setopenstatusdialog(false);
  };

  // --------------------------------------------------------------------------------

  return (
    <DashboardMainContainer>
      <Page
        title="Job Positions"
        handleFunction={handleNavigate}
        buttonname="New Position"
      />
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "100%", py: 2, marginBottom: "20px" }}
      >
        {openings ? (
          openings
            .filter((item) => item.id !== selectedid)
            .map((item) => (
              <Accordion
                key={item.id}
                item
                expanded={expanded === item.id}
                onChange={handleChange(item.id, item.jobid)}
                sx={{
                  width: "90%",
                  border: "1px solid #d3e1ea",
                }}
              >
                <AccordionSummary expandIcon={<IconDownArrow />}>
                  <Stack
                    direction={Mobile || Tab ? "column" : "row"}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "98%" }}
                  >
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems="center"
                      justifyContent="left"
                      spacing={1}
                      sx={{
                        width: "98%",
                        textAlign: Mobile || Tab ? "center" : "left",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        {item.jobid}
                      </Typography>
                      {Mobile || Tab ? null : (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          |
                        </Typography>
                      )}
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        {item.jobtitle}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={Mobile || Tab ? "center" : "right"}
                      spacing={3}
                      sx={{ width: "50%" }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        sx={{
                          p: 0.5,
                          background:
                            item.status === "active" ? "green" : "red",
                          color: "white",
                          borderRadius: "5px",
                        }}
                      >
                        <IconDot />
                        <Typography
                          variant="body1"
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {item.status}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Stack direction="column" alignItems="left" spacing={1}>
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections2
                        keydata="Domain Added"
                        value={handleDomain(item.domain)}
                      />

                      <TextSections
                        keydata="Experience"
                        value={item.experience}
                      />

                      <TextSections keydata="Time Zone" value={item.timezone} />
                    </Stack>
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections keydata="Job Type" value={item.jobtype} />
                      <TextSections
                        keydata="Job Duration"
                        value={item.duration === null ? "NA" : item.duration}
                      />
                    </Stack>
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections keydata="Job Mode" value={item.jobmode} />
                      <TextSections
                        keydata="Job Location"
                        value={item.location}
                      />
                    </Stack>
                    <TextSections keydata="Job Description" />

                    {item.description === null
                      ? null
                      : item.description.map((item, index) => (
                          <Stack
                            direction="row"
                            alignItems="start"
                            spacing={1}
                            key={item.did}
                          >
                            <Typography variant="body1">{item.did}.</Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                textTransform: "capitalize",
                                "&:first-letter": {
                                  textTransform: "uppercase",
                                },
                              }}
                            >
                              {item.ddata}
                            </Typography>
                          </Stack>
                        ))}
                    <Divider />
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections
                        keydata="Total Applicants"
                        value={item.totalapplied}
                      />
                      <TextSections
                        keydata="No.of Selected"
                        value={item.selected}
                      />
                      <TextSections
                        keydata="No.of Rejected"
                        value={item.rejected}
                      />
                    </Stack>
                    <Divider />
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections keydata="Posted by" value={item.postedby} />

                      <TextSections
                        keydata="Updated by"
                        value={item.updatedby}
                      />
                    </Stack>
                    <Divider />
                    <Stack
                      direction={Mobile || Tab ? "column" : "row"}
                      alignItems={Mobile || Tab ? "left" : "center"}
                      justifyContent={Mobile || Tab ? "left" : "space-between"}
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <TextSections
                        keydata="Posted On"
                        value={handleDate(item.postedon)}
                      />
                      <Stack
                        direction={Mobile || Tab ? "column" : "row"}
                        alignItems={Mobile || Tab ? "left" : "center"}
                        justifyContent={Mobile || Tab ? "left" : "right"}
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<IconEdit />}
                          onClick={() => handleEditform(item)}
                        >
                          Edit Status of Position
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<IconEdit />}
                          onClick={() => handleEdit(item)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<IconDelete />}
                          onClick={() => handleOpenDelete(item.id)}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))
        ) : (
          <Typography>No Positions are Available</Typography>
        )}
      </Stack>
      {/* Create Position */}
      <Dialog open={opendialog} maxWidth="md">
        <DialogContent
          sx={{ width: "550px", height: "500px", p: 2, marginBottom: "10px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", py: 1 }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {selectedid ? "Edit Position" : "Create Position"}
            </Typography>
            <IconButton onClick={handleCloseDialog}>
              <IconClose />
            </IconButton>
          </Stack>
          <CreatePosition
            Selid={selectedid}
            SelObject={selectedObject}
            closeDialog={handleCloseDialog}
            UpdatedRow={handleUpdateRow}
          />
        </DialogContent>
      </Dialog>

      {/* Delete Position */}

      <Dialog open={deleteDialog}>
        <DialogContent sx={{ p: 2 }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%", py: 1 }}
            spacing={2}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Are you Sure ?
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Button variant="contained" type="button" onClick={handleDelete}>
                Delete
              </Button>
              <Button
                variant="oulined"
                type="button"
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Update Status */}
      <Dialog open={openstatusdialog}>
        <DialogContent sx={{ width: Mobile || Tab ? "auto" : "550px", p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", py: 1 }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Edit Status of Position
            </Typography>
          </Stack>
          <PositionStatus
            Selected={statusObject}
            closeDialog={handleCloseEditForm}
          />
        </DialogContent>
      </Dialog>
    </DashboardMainContainer>
  );
}

export default OpenPositions;
