import React from "react";
import { useAuth } from "./Authentication";
import { useNavigate } from "react-router-dom";

function RequireAuth({ children }) {
  const auth = useAuth();
  const Navigate = useNavigate();

  if (auth.isAuthenticated === false) {
    Navigate("/admin/login");
  }
  return children;
}

export default RequireAuth;
