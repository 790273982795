import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Button, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth/Authentication";

const schema = yup.object({
  currentpassword: yup.string().required("Old Password is Required"),
  newpassword: yup
    .string()
    .required("New Password  is Required")
    .min(8, "Password must be at least 8 characters long"),
  cpassword: yup
    .string()
    .required("Confirm Password  is Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  currentpassword: "",
  newpassword: "",
  cpassword: "",
};

function ChangePassword() {
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const token = user === null ? null : user.token;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      component="form"
      sx={{ width: "60%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        label="Old Password"
        fullWidth
        autoComplete="off"
        error={errors.currentpassword}
        helperText={errors.currentpassword?.message}
        {...register("currentpassword")}
      />
      <TextField
        label="New Password"
        fullWidth
        autoComplete="off"
        error={errors.newpassword}
        helperText={errors.newpassword?.message}
        {...register("newpassword")}
      />
      <TextField
        label="Confirm Password"
        fullWidth
        autoComplete="off"
        error={errors.cpassword}
        helperText={errors.cpassword?.message}
        {...register("cpassword")}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="right"
        sx={{ width: "100%" }}
      >
        <Button variant="contained" type="submit">
          Change Password
        </Button>
      </Stack>
    </Stack>
  );
}

export default ChangePassword;
