import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import NotFound from "../pages/others/NotFound";
import Analytics from "../pages/dashboards/Analytics";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Applications from "../pages/dashboards/CareersPages/Applications";
import OpenPositions from "../pages/dashboards/CareersPages/OpenPositions";
import Enquiries from "../pages/dashboards/Enquiries";
import Team from "../pages/dashboards/Team";
import OTP from "../pages/auth/OTP";
// import Webinars from "../pages/dashboards/Webinars/Webinars";
import EmployeReviews from "../pages/dashboards/CareersPages/EmployeReviews";
import Banners from "../pages/dashboards/Website/Banners";
import Logos from "../pages/dashboards/Website/Logos";
import Testimonials from "../pages/dashboards/Feedbacks/Testimonials";
import MFeedbacks from "../pages/dashboards/Feedbacks/MFeedbacks";
import WebPictures from "../pages/dashboards/Website/WebPictures";
// import WebinarReg from "../pages/dashboards/Webinars/WebinarReg";
import RequireAuth from "../auth/RequireAuth";
// import Createwebinar from "../components/Forms/Webinars/Createwebinar";
import CreatePosition from "../components/Forms/careers/CreatePositions";
import CreateBanners from "../components/Forms/Webisite/CreateBanners";
import Addlogos from "../components/Forms/Webisite/Addlogos";
import LifeatKtech from "../pages/dashboards/Website/LifeatKtech";
import Leadership from "../pages/dashboards/Website/Leadership";
import CreateKtech from "../components/Forms/Webisite/CreateKtech";
import CreateManagement from "../components/Forms/Webisite/CreateManagement";
import GlobalBranches from "../pages/dashboards/Website/GlobalBranches";
import CreateWebPictures from "../components/Forms/Webisite/CreateWebPictures";
import CreateGbranch from "../components/Forms/Webisite/CreateGbranch";
import Countries from "../pages/dashboards/Website/Countries";
import CreateCountry from "../components/Forms/Webisite/CreateCountry";
import Eventslist from "../pages/Events/Eventslist";
import EventsRegistrations from "../components/Forms/events/EventsRegistrations";
import CreateEvent from "../components/Forms/events/CreateEvent";
import PageIcons from "../pages/dashboards/Website/PageIcons";
import AddIcons from "../components/Forms/Webisite/AddIcons";
import Industries from "../pages/dashboards/Website/Industries";
import CreateIndustry from "../components/Forms/Webisite/CreateIndustry";
import CreateEmployee from "../components/Forms/CreateEmployee";
import Settings from "../pages/auth/Settings";
// ------------------------------------------------------

// Dashboard Pages

// const Analytics = lazy(() => import("../pages/dashboards/Analytics"));
// const OpenPositions = lazy(() =>
//   import("../pages/dashboards/CareersPages/OpenPositions")
// );

// const Applicants = lazy(() =>
//   import("../pages/dashboards/CareersPages/Applications")
// );
// const Visitors = lazy(() => import("../pages/dashboards/Visitors"));
// const Enquiries = lazy(() => import("../pages/dashboards/Enquiries"));
// const LifeatKCS = lazy(() => import("../pages/dashboards/LifeatKcs"));

// // Other Pages

// const NotFound = lazy(() => import("../pages/others/NotFound"));

// // Auth Pages

// const Login = lazy(() => import("../pages/auth/Login"));
// const Register = lazy(() => import("../pages/auth/Register"));
// const OTP = lazy(() => import("../pages/auth/OTP"));
// const Login = lazy(() => import("../pages/auth/Login"));
// const Login = lazy(() => import("../pages/auth/Login"));
// const Login = lazy(() => import("../pages/auth/Login"));

// -----------------------------------------------------

function AdminRoutes() {
  return useRoutes([
    {
      path: "/",
      children: [
        //   Page After Load (Indexed Page)

        {
          path: "/admin/404",
          element: <NotFound />,
        },

        {
          path: "*",
          element: <Navigate to="/admin/404" />,
        },

        {
          path: "/",

          element: <Navigate to="/admin/login" exact replace />,
        },

        {
          path: "admin",
          children: [
            //   Dashboard
            {
              path: "",

              element: <Navigate to="/admin/login" exact replace />,
            },

            {
              path: "home",
              element: (
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              ),
            },
            {
              path: "careers",
              children: [
                {
                  path: "job-positions",
                  element: (
                    <RequireAuth>
                      <OpenPositions />
                    </RequireAuth>
                  ),
                },
                {
                  path: "job-positions/create",
                  element: (
                    <RequireAuth>
                      <CreatePosition />
                    </RequireAuth>
                  ),
                },
                {
                  path: "job-positions/edit",
                  element: (
                    <RequireAuth>
                      <CreatePosition />
                    </RequireAuth>
                  ),
                },
                {
                  path: "careers",
                  element: (
                    <Navigate to="careers/job-positions" exact replace />
                  ),
                },

                {
                  path: "applications",
                  element: (
                    <RequireAuth>
                      <Applications />
                    </RequireAuth>
                  ),
                },

                {
                  path: "employees-reviews",
                  element: (
                    <RequireAuth>
                      <EmployeReviews />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: "team",
              element: (
                <RequireAuth>
                  <Team />
                </RequireAuth>
              ),
            },
            {
              path: "team/create",
              element: (
                <RequireAuth>
                  <CreateEmployee />
                </RequireAuth>
              ),
            },
            {
              path: "team/edit",
              element: (
                <RequireAuth>
                  <CreateEmployee />
                </RequireAuth>
              ),
            },
            {
              path: "enquiries",
              element: (
                <RequireAuth>
                  <Enquiries />
                </RequireAuth>
              ),
            },

            {
              path: "website",
              children: [
                {
                  path: "banners",
                  element: (
                    <RequireAuth>
                      <Banners />
                    </RequireAuth>
                  ),
                },
                {
                  path: "website",
                  element: <Navigate to="website/banners" exact replace />,
                },
                {
                  path: "logos",
                  element: (
                    <RequireAuth>
                      <Logos />
                    </RequireAuth>
                  ),
                },
                {
                  path: "web-pictures",
                  element: (
                    <RequireAuth>
                      <WebPictures />
                    </RequireAuth>
                  ),
                },
                {
                  path: "life-at-ktech",
                  element: (
                    <RequireAuth>
                      <LifeatKtech />
                    </RequireAuth>
                  ),
                },
                {
                  path: "life-at-ktech/create",
                  element: (
                    <RequireAuth>
                      <CreateKtech />
                    </RequireAuth>
                  ),
                },
                {
                  path: "life-at-ktech/edit",
                  element: (
                    <RequireAuth>
                      <CreateKtech />
                    </RequireAuth>
                  ),
                },
                {
                  path: "leadership",
                  element: (
                    <RequireAuth>
                      <Leadership />
                    </RequireAuth>
                  ),
                },

                {
                  path: "leadership/create",
                  element: (
                    <RequireAuth>
                      <CreateManagement />
                    </RequireAuth>
                  ),
                },

                {
                  path: "leadership/edit",
                  element: (
                    <RequireAuth>
                      <CreateManagement />
                    </RequireAuth>
                  ),
                },

                // Icons

                {
                  path: "pageicons",
                  element: (
                    <RequireAuth>
                      <PageIcons />
                    </RequireAuth>
                  ),
                },

                {
                  path: "pageicons/create",
                  element: (
                    <RequireAuth>
                      <AddIcons />
                    </RequireAuth>
                  ),
                },

                {
                  path: "pageicons/edit",
                  element: (
                    <RequireAuth>
                      <AddIcons />
                    </RequireAuth>
                  ),
                },
                {
                  path: "banners/create",
                  element: (
                    <RequireAuth>
                      <CreateBanners />
                    </RequireAuth>
                  ),
                },
                {
                  path: "banners/edit",
                  element: (
                    <RequireAuth>
                      <CreateBanners />
                    </RequireAuth>
                  ),
                },
                {
                  path: "logos/create",
                  element: (
                    <RequireAuth>
                      <Addlogos />
                    </RequireAuth>
                  ),
                },
                {
                  path: "logos/edit",
                  element: (
                    <RequireAuth>
                      <Addlogos />
                    </RequireAuth>
                  ),
                },
                {
                  path: "globalfootprints",
                  element: (
                    <RequireAuth>
                      <GlobalBranches />
                    </RequireAuth>
                  ),
                },
                {
                  path: "globalfootprints/create",
                  element: (
                    <RequireAuth>
                      <CreateGbranch />
                    </RequireAuth>
                  ),
                },
                {
                  path: "globalfootprints/edit",
                  element: (
                    <RequireAuth>
                      <CreateGbranch />
                    </RequireAuth>
                  ),
                },
                {
                  path: "countries",
                  element: (
                    <RequireAuth>
                      <Countries />
                    </RequireAuth>
                  ),
                },
                {
                  path: "countries/create",
                  element: (
                    <RequireAuth>
                      <CreateCountry />
                    </RequireAuth>
                  ),
                },
                {
                  path: "countries/edit",
                  element: (
                    <RequireAuth>
                      <CreateCountry />
                    </RequireAuth>
                  ),
                },
                {
                  path: "web-pictures",
                  element: (
                    <RequireAuth>
                      <WebPictures />
                    </RequireAuth>
                  ),
                },
                {
                  path: "web-pictures/create",
                  element: (
                    <RequireAuth>
                      <CreateWebPictures />
                    </RequireAuth>
                  ),
                },
                {
                  path: "web-pictures/edit",
                  element: (
                    <RequireAuth>
                      <CreateWebPictures />
                    </RequireAuth>
                  ),
                },
                {
                  path: "industries",
                  element: (
                    <RequireAuth>
                      <Industries />
                    </RequireAuth>
                  ),
                },
                {
                  path: "industries/create",
                  element: (
                    <RequireAuth>
                      <CreateIndustry />
                    </RequireAuth>
                  ),
                },
                {
                  path: "industries/edit",
                  element: (
                    <RequireAuth>
                      <CreateIndustry />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: "feedbacks",
              children: [
                {
                  path: "monthly-feedbacks",
                  element: (
                    <RequireAuth>
                      <MFeedbacks />
                    </RequireAuth>
                  ),
                },
                {
                  path: "feedbacks",
                  element: (
                    <Navigate to="feedbacks/monthly-feedbacks" exact replace />
                  ),
                },
                {
                  path: "Testimonials",
                  element: (
                    <RequireAuth>
                      <Testimonials />
                    </RequireAuth>
                  ),
                },
              ],
            },

            {
              path: "events",
              children: [
                {
                  path: "list",
                  element: (
                    <RequireAuth>
                      <Eventslist />
                    </RequireAuth>
                  ),
                },
                {
                  path: "events",
                  element: <Navigate to="events/list" exact replace />,
                },
                {
                  path: "registrations",
                  element: (
                    <RequireAuth>
                      <EventsRegistrations />
                    </RequireAuth>
                  ),
                },
                {
                  path: "create",
                  element: (
                    <RequireAuth>
                      <CreateEvent />
                    </RequireAuth>
                  ),
                },
                {
                  path: "edit",
                  element: (
                    <RequireAuth>
                      <CreateEvent />
                    </RequireAuth>
                  ),
                },
              ],
            },

            // Events

            //   Authentication

            {
              path: "register",
              element: <Register />,
            },
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
            {
              path: "otp",
              element: <OTP />,
            },
          ],
        },
      ],
    },
  ]);
}

export default AdminRoutes;
