import React, { useEffect, useMemo, useState } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Domains, depts, types } from "../../../mock/FormFields";
import { IconEdit } from "../../../themes/Icons";
import { ErrorMessage } from "@hookform/error-message";
import { addLogo, updateLogo } from "../../../api/website/Logos";
import { createBanner, updateBanner } from "../../../api/website/Banners";
import { createImage, updateImage } from "../../../api/website/LAKtech";

const schema = yup.object({
  eventname: yup.string().required("Event Name is Required"),
  thumbnail: yup.mixed().required("Image is Required"),
  show: yup.string().required("Display Validation is Required"),
  domain: yup
    .array()
    .min(1, "At least one domain must be selected")
    .required("Domain is Required"),
});

function CreateKtech() {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [base64, setbase64] = useState(null);

  const [editImage, setEditImage] = useState(false);

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector(
    (state) => state.lifeatktech.lifektechObject
  );
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/life-at-ktech");
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const defaultValues = useMemo(
    () => ({
      eventname: SelObject.eventname || "",
      show: SelObject.showimage || "",
      thumbnail: SelObject.eventimage || null,
      domain: SelObject.domain || [],
    }),
    [SelObject]
  );

  const Base64ToBinaryImage = (base64String) => {
    // Convert base64 string to binary

    // Convert base64 string to binary
    const binaryString = window.atob(base64String);

    // Create a Uint8Array to hold the binary data
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: "image/svg+xml" });

    // Create a URL for the Blob
    // console.log(blob);
    return blob;
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const EditPic = SelObject.eventimage;

  const onSubmit = (data) => {
    console.log(data);
    if (paths === "edit") {
      const binaryfile = editImage
        ? data.thumbnail
        : Base64ToBinaryImage(EditPic);

      console.log(binaryfile);
      updateImage(selectedId, data, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });

            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      // console.log("create");
      // console.log(data);
      createImage(data, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };
  return (
    <DashboardMainContainer
      direction="column"
      alignItems="center"
      bgcolorcondition={bgcondition}
    >
      <Page
        title={
          paths !== "edit"
            ? "Add Image for Life @ KTECH"
            : "Update Image for Life @ KTECH"
        }
      />
      <Grid
        sx={{
          width: "90%",
          // border: "1px solid blue",
          p: 2,
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "450px" }}
          component={Grid}
          item
          xs={12}
          md={12}
          spacing={1}
        >
          {base64 || (EditPic && editImage !== true) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "400px",
                width: "auto",
                objectFit: "contain",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              {editImage || base64 ? (
                <img
                  src={`data:image/svg+xml;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={`data:image/svg+xml;base64,${base64 || EditPic}`}
                  style={{
                    objectPosition: "center center",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
              {EditPic && !base64 ? (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              ) : null}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "400px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              htmlFor="pic"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture !
              </Typography>

              <Controller
                name="thumbnail"
                control={control}
                render={({ field }) => (
                  <input
                    id="pic"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        console.log(base64, "Logo");
                        const CleanResume = base64.substring(
                          "data:image/svg+xml;base64,".length
                        );

                        setbase64(CleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="thumbnail"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="left"
          spacing={2}
          sx={{ width: "100%", height: "300px" }}
          component={Grid}
          item
          xs={12}
          md={12}
        >
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("eventname")}
              label="Event Name"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="eventname"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("show")}
              label="Show Image"
              autoComplete="off"
              fullWidth
            />
            <ErrorMessage
              errors={errors}
              name="show"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="domain"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            Domains.find((c) => `${c.domainvalue}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.domainname}`}
                    options={Domains}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.domainvalue}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Display in" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.domainname}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.domainvalue}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="right"
            sx={{ width: "100%" }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateKtech;
