import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  DigitalMarketing,
  HRNavigation,
  PreSalesNavigations,
  SalesNavigations,
  SidebarData,
  cSubmenu,
} from "../../mock/Navigations";
import { useSelector } from "react-redux";
import LogoComponent from "./LogoComponent";

export const MainHeader = styled("nav")(({ theme, color, toggle }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  flexDirection: "column",
  width: "300px",
  height: "calc(100% - 75px)",
  background: theme.palette.primary.main,
  overflowY: "scroll",
  position: "fixed",
  bottom: 0,

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
    left: toggle ? 0 : "-200%",
    zIndex: 9999,
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));
export const MainNavList = styled("ul")(({ theme, color, toggle }) => ({
  display: "flex",
  alignItems: "start",
  justifyContent: "left",
  flexDirection: "column",
  position: "relative",
  // top: "100px",

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const MainNavItem = styled("li")(({ theme, color }) => ({
  // css
  listStyle: "none",
  // padding: "12px",
  paddingBottom: "20px",
  paddingTop: "20px",
  // border: "1px solid blue",
  width: "100%",

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const MainNavLink = styled(NavLink)(({ theme, color }) => ({
  // css
  color: "white",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: 2,
  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const MainNavText = styled(Stack)(({ theme, color }) => ({
  // css
  color: "white",
  cursor: "pointer",

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const SubNavList = styled("ul")(({ theme, color }) => ({
  // css

  display: "flex",
  alignItems: "left",
  justifyContent: "left",
  flexDirection: "column",

  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const SubNavItem = styled("li")(({ theme, color }) => ({
  // css
  listStyle: "none",
  paddingTop: "30px",
  paddingBottom: "0px",
  textAlign: "left",
  position: "relative",
  left: 0,
  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

export const SubNavLink = styled(NavLink)(({ theme, color }) => ({
  // css
  color: "white",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: 2,
  [theme.breakpoints.between("xs", "md")]: {
    // mobile
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

function Sidebar({ Toggle, ToggleClose }) {
  const [menuid, setMenuid] = useState(0);

  const userdata = useSelector((state) => state.login.user);

  const roleid = userdata ? userdata.roleid : 0;

  const [openSubmenu, setopenSubmenu] = useState(false);

  const handleOpenMenu = (id) => {
    console.log(id);
    setMenuid(id);
    setopenSubmenu(true);
  };

  let Navdata;

  switch (roleid) {
    case 0:
      Navdata = SidebarData;
      break;
    case 1:
      Navdata = HRNavigation;
      break;
    case 2:
      Navdata = PreSalesNavigations;
      break;
    case 3:
      Navdata = SalesNavigations;
      break;
    case 4:
      Navdata = DigitalMarketing;
      break;
  }

  return (
    <MainHeader className="AdminNav" toggle={Toggle}>
      <MainNavList>
        {Navdata.map((item) => (
          <MainNavItem onClick={() => handleOpenMenu(item.id)} key={item.id}>
            {item.path ? (
              <MainNavLink to={item.path} onClick={ToggleClose}>
                {item.icon}
                {item.title}
              </MainNavLink>
            ) : (
              <MainNavText direction="row" alignItems="center" spacing={2}>
                {item.icon}
                <Typography variant="body">{item.title}</Typography>
                {item.submenu && openSubmenu && item.submenuid === menuid
                  ? item.iconopen
                  : item.submenu
                  ? item.iconclose
                  : null}
              </MainNavText>
            )}
            {item.submenu && openSubmenu && item.submenuid === menuid ? (
              <SubNavList>
                {item.submenu.map((item) => (
                  <SubNavItem key={item.id}>
                    <SubNavLink to={item.path} onClick={ToggleClose}>
                      {item.icon}
                      {item.title}
                    </SubNavLink>
                  </SubNavItem>
                ))}
              </SubNavList>
            ) : null}
          </MainNavItem>
        ))}
      </MainNavList>
    </MainHeader>
  );
}

export default Sidebar;
