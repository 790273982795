import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  IconAdd,
  IconClose,
  IconDelete,
  IconEdit,
  IconRightArrow,
} from "../../../themes/Icons";
import { createPosition, editApplicant, updatePositionStatus } from "../../../api/Careers";
import { countrys, statusdata } from "../../../mock/FormFields";
import { useAuth } from "../../../auth/Authentication";
import { useSnackbar } from "notistack";

const schema = yup.object({
  status: yup.string(),
});

const Status = [
  {
    id: 1,
    label: "Applied",
    value: "applied",
  },
  {
    id: 2,
    label: "Contacted",
    value: "contacted",
  },
  {
    id: 3,
    label: "Reviewed",
    value: "reviewed",
  },
  {
    id: 4,
    label: "Selected",
    value: "selected",
  },
  {
    id: 5,
    label: "Rejected",
    value: "rejected",
  },
];

function PostionStatus({ Selected, closeDialog }) {
  const { user } = useAuth();
  const token = user.accesstoken;
  // ----------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      status: Selected.status || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Selected]
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (Selected) {
      reset(defaultValues);
    }
  }, [Selected]);

  const onSubmit = (data) => {
    const id = Selected.jobid;
    updatePositionStatus(id, data, token)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status === true) {
          closeDialog();
          enqueueSnackbar(res.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={2}
      sx={{ width: "100%", py: 2 }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="status"
        control={control}
        render={({ field }) => {
          const { onChange, value } = field;
          return (
            <Autocomplete
              fullWidth
              value={
                value
                  ? statusdata.find((option) => {
                      return value == option.statusvalue;
                    }) ?? null
                  : null
              }
              getOptionLabel={(option) => {
                return option.statusname;
              }}
              options={statusdata}
              onChange={(e, newValue) => {
                onChange(newValue ? newValue.statusvalue : null);
              }}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          );
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Button variant="contained" type="submit" startIcon={<IconEdit />}>
          Edit
        </Button>
        <Button
          variant="outlined"
          type="button"
          startIcon={<IconClose />}
          onClick={closeDialog}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

export default PostionStatus;
