import React, { Fragment, useEffect, useState } from "react";
import { DashboardMainContainer } from "../Analytics";
import Page from "../../../components/common/Page";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  IconClose,
  IconDelete,
  IconDot,
  IconDownArrow,
  IconEdit,
} from "../../../themes/Icons";
import { TextSections, TextSections2 } from "./OpenPositions";
import { Link } from "react-router-dom";
import { getApplications, getPositions, getResume } from "../../../api/Careers";
import Applicant from "../../../components/Forms/careers/Applicant";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { APIURL } from "../../../config";
import { saveAs } from "file-saver";
import { useAuth } from "../../../auth/Authentication";

export const LinkSection = ({ keydata, value, link }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography
        variant="body1"
        component={Link}
        to={link}
        sx={{
          textDecoration: "none",
          color: "black",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export const LinkSection1 = ({ keydata, value, link }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography
        variant="body1"
        component="a"
        target="blank"
        sx={{
          textDecoration: "none",
          color: "black",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

function Applications() {
  const { user } = useAuth();
  const token = user.accesstoken;

  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const Tab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));
  // ----------------------------------------------------------------------------
  const { enqueueSnackbar } = useSnackbar();

  const [SelObject, setSelObject] = React.useState({});
  const [Status, setStatus] = React.useState("");
  const [Applications, setApplications] = useState([]);
  const [Positions, setPositions] = useState([]);
  const [opendialog, setopenDialog] = useState(false);
  const [ResumeView, setResumeView] = useState("");
  const [FileName, setFileName] = useState("");

  const Naviagte = useNavigate();

  useEffect(() => {
    getApplications(token)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        setStatus(status);
        setApplications(res.data.response);
        if (status === true) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    getPositions(token)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        setStatus(status);
        setPositions(res.data.response);
        if (status === true) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // -------------------------------------------------------- Dates and Resume

  const handleDate = (apon) => {
    const date = new Date(apon);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const dateandtime = `${day}-${month}-${year} ${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return dateandtime;
  };

  const handleDownload = (id, trackid, name, resume) => {
    // console.log(name,resume)
    try {
      // Convert base64 to Blob
      const byteCharacters = atob(resume);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const pdfBlob = new Blob([byteArray], { type: "application/pdf" });

      // Trigger download
      saveAs(pdfBlob, `${trackid}-${name}` || "document.pdf");
    } catch (error) {
      console.error("Error downloading PDF:", error.message);
    }
  };

  // --------------------------------------------------------

  const handleOpenForm = (selobj) => {
    setopenDialog(true);
    setSelObject(selobj);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const handleStatusData = (status) => {
    console.log(status);
  };

  const [filterdata, setfilterdata] = useState("");
  return (
    <DashboardMainContainer direction="column" alignItems="center">
      <Page title="Applications" />

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ width: "100%", my: 2 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "80%" }}
        >
          <TextField
            fullWidth
            label="Select Job to filter "
            value={filterdata}
            onChange={(e) => setfilterdata(e.target.value)}
            select
          >
            {Positions.map((item) => (
              <MenuItem key={item.id} value={item.jobid}>
                {`${item.jobid} - ${item.jobtitle}`}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        {Status === false ? (
          <Typography variant="h6">No Applications Found</Typography>
        ) : (
          Applications.filter((item) =>
            item.appliedpid.includes(filterdata)
          ).map((item) => (
            <Accordion
              item
              expanded={expanded === item.id}
              onChange={handleChange(item.id)}
              sx={{ width: "95%" }}
              key={item.id}
            >
              <AccordionSummary expandIcon={<IconDownArrow />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {item.trackid}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {item.firstname} {item.lastname}
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ mr: "5px", width: "30%" }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {item.domain}
                    </Typography>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      sx={{
                        p: 0.5,
                        color:
                          item.status === "selected"
                            ? "green"
                            : item.status === "rejected"
                            ? "red"
                            : item.status === "contacted"
                            ? "blue"
                            : "black",

                        borderRadius: "5px",
                      }}
                    >
                      <IconDot />
                      <Typography
                        variant="body1"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {item.status}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Stack direction="column" alignItems="left" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSections
                      keydata="Applied to"
                      value={`${item.appliedpid} - ${item.appliedpname}`}
                    />

                    <TextSections
                      keydata="Willing to Relocate"
                      value={item.relocate}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSections
                      keydata="Internal Referance"
                      value={item.internalreferance}
                    />

                    {item.internalreferance === "Yes" ? (
                      <TextSections keydata="Employee ID" value={item.empid} />
                    ) : null}

                    {item.internalreferance === "Yes" ? (
                      <TextSections
                        keydata="Employee Name"
                        value={item.empname}
                      />
                    ) : null}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSections2 keydata="Email" value={item.email} />
                    <TextSections keydata="Mobile" value={item.mobile} />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSections
                      keydata="Experience"
                      value={item.experience}
                    />

                    <TextSections
                      keydata="Relavent Experience"
                      value={item.experience}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <TextSections keydata="CTC" value={item.ctc} />

                    <TextSections keydata="Expected CTC" value={item.ectc} />
                    <TextSections
                      keydata="Notice Period"
                      value={item.noticeperiod}
                    />
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Status
                    </Typography>
                    <Typography variant="body1">:</Typography>
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item.status}
                    </Typography>

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => handleOpenForm(item)}
                      sx={{ cursor: "pointer  " }}
                    >
                      <IconEdit />
                    </Stack>
                  </Stack>

                  <Divider />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Resume
                      </Typography>
                      <Typography variant="body1">:</Typography>

                      <Typography
                        variant="body1"
                        onClick={() =>
                          handleDownload(
                            item.id,
                            item.trackid,
                            item.filename,
                            item.resume
                          )
                        }
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "black",
                        }}
                      >
                        Download Resume
                      </Typography>
                    </Stack>
                    <TextSections
                      keydata="Applied On"
                      value={handleDate(item.appliedon)}
                    />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Stack>

      <Dialog open={opendialog}>
        <DialogContent sx={{ width: "550px", p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", py: 1 }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Edit Status of Applicant
            </Typography>
          </Stack>
          <Applicant Selected={SelObject} closeDialog={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </DashboardMainContainer>
  );
}

export default Applications;
