import { Button, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { ResendOtp } from "../../api/Auth";
import { useSnackbar } from "notistack";

function Counter({ user }) {
  const [remainingTime, setremainingTime] = useState(120);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      // Decrease the timer value by 1 second
      setremainingTime((prevSeconds) => {
        // Stop the countdown when it reaches 0
        if (prevSeconds === 0) {
          clearInterval(timer);
          // You can add additional logic here for what to do when the timer reaches 0
        }
        return prevSeconds === 0 ? 0 : prevSeconds - 1;
      });
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let timer;

    if (isActive) {
      timer = setInterval(() => {
        setremainingTime((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(timer);
            setIsActive(false);
            // You can add additional logic here for what to do when the timer reaches 0
          }
          return prevSeconds === 0 ? 0 : prevSeconds - 1;
        });
      }, 1000);
    }

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, [isActive]);

  const startCounter = () => {
    setIsActive(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleResendOtp = () => {
    startCounter();
    ResendOtp(user)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status === true) {
          enqueueSnackbar(res.data.message, { variant: "success" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const formattedTime = `${String(Math.floor(remainingTime / 60)).padStart(
    2,
    "0"
  )}:${String(remainingTime % 60).padStart(2, "0")}`;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      sx={{ width: "inherit" }}
    >
      <Typography
        variant="body1"
        component={Button}
        sx={{ fontWeight: "bold", fontSize: "20px" }}
        onClick={handleResendOtp}
        disabled={remainingTime !== 0}
      >
        Resend OTP
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "20px" }}>
        {formattedTime}
      </Typography>
    </Stack>
  );
}

export default Counter;
