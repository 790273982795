import React, { Fragment } from "react";
import Page from "../../../components/common/Page";
import { DashboardMainContainer } from "../Analytics";

function Careers() {
  return (
    <DashboardMainContainer>
      <Page title="Employees Reviews" />
    </DashboardMainContainer>
  );
}

export default Careers;
