export const pagespath = [
  {
    id: 1,
    title: "Home",
    pathname: "/home",
  },
  {
    id: 2,
    title: "Company",
    pathname: "/about-us/company",
  },
  {
    id: 3,
    title: "Leadership",
    pathname: "/about-us/our-team",
  },
  {
    id: 4,
    title: "Infor Consulting",
    pathname: "/services/infor-consulting",
  },
  {
    id: 5,
    title: "SAP Conslting",
    pathname: "/services/sap-consulting",
  },
  {
    id: 6,
    title: "Oracle Netsuite",
    pathname: "/services/oracle-netsuite",
  },
  {
    id: 7,
    title: "Odoo Consulting",
    pathname: "/services/odoo-consulting",
  },
  {
    id: 8,
    title: "Microsoft Dynamics Business Central",
    pathname: "/services/microsoft-dynamics-business-central",
  },

  {
    id: 10,
    title: "Infor",
    pathname: "/products/Infor",
  },

  {
    id: 11,
    title: "Mpdv",
    pathname: "/products/mpdv",
  },

  {
    id: 12,
    title: "Finsta",
    pathname: "/products/finsta",
  },

  {
    id: 13,
    title: "IFCA",
    pathname: "/products/ifca",
  },

  {
    id: 14,
    title: "Hexabuild",
    pathname: "/products/hexabuild",
  },
  {
    id: 15,
    title: "Odoo",
    pathname: "/products/odoo",
  },
  {
    id: 16,
    title: "Application Development",
    pathname: "/other-services/application-development",
  },
  {
    id: 17,
    title: "IT Staffing",
    pathname: "/other-services/it-staffing",
  },
  {
    id: 16,
    title: "TAAS",
    pathname: "/other-services/taas",
  },
  {
    id: 17,
    title: "Training",
    pathname: "/other-services/technology-training-services",
  },
  {
    id: 18,
    title: "Careers",
    pathname: "/join-us/careers",
  },
  {
    id: 19,
    title: "Job Openings",
    pathname: "/join-us/job-openings",
  },
  {
    id: 20,
    title: "Job Description",
    pathname: "/join-us/job-description",
  },
  {
    id: 21,
    title: "Events",
    pathname: "/join-us/events",
  },
  {
    id: 22,
    title: "Contact",
    pathname: "/contact-us",
  },
];
