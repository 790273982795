import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createBanner = async (data, token) => {
  const formdata = new FormData();
  formdata.append("name", data.name);
  formdata.append("pagename", data.pagename);
  formdata.append("pagepath", data.pagepath);
  formdata.append("thumbnail", data.thumbnail);
  formdata.append("domain", JSON.stringify(data.domain));

  const response = await axios.post(apilinks.banners.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getBanners = async () => {
  const response = await axios.get(apilinks.banners.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateBanner = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  formdata.append("name", data.name);
  formdata.append("pagename", data.pagename);
  formdata.append("pagepath", data.pagepath);
  formdata.append("thumbnail", binaryfile);
  formdata.append("domain", JSON.stringify(data.domain));

  const response = await axios.put(
    `${apilinks.banners.update}/${id}`,
    formdata,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/formdata",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  DELETE
