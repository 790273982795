import React, { Fragment, useEffect, useState } from "react";
import Page from "../../../components/common/Page";
import { useNavigate } from "react-router-dom";
import { DashboardMainContainer } from "../Analytics";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logoObject } from "../../../redux/slices/website/Logos";
import { IconDelete, IconEdit } from "../../../themes/Icons";
import { getLogos } from "../../../api/website/Logos";
import { useSnackbar } from "notistack";
import { getCountries } from "../../../api/website/Countries";
import { countriesObject } from "../../../redux/slices/website/Countries";

function Countries() {
  const Navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [logos, setlogos] = useState([]);

  const handleNavigate = () => {
    Navigate("/admin/website/countries/create");
  };

  const handleEdit = (item) => {
    dispatch(countriesObject(item));
    Navigate("/admin/website/countries/edit");
  };

  useEffect(() => {
    getCountries()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        // UpdatedRow(res.data.response);
        if (status === true) {
          setlogos(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);

  return (
    <DashboardMainContainer>
      <Page
        title="Countries"
        // handleFunction={handleNavigate}
        // buttonname="Add New Country"
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "100%", py: 2, marginBottom: "20px", height: "auto" }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {logos.length === 0 ? (
          <Typography>No Countries Found !</Typography>
        ) : (
          logos.map((item) => (
            <Card
              item
              key={item.id}
              spacing={1}
              component={Grid}
              sx={{
                width: { xs: "400px", sm: "350px", md: "300px" },
                height: "450px",
                // border: `1px solid ${theme.palette.primary.main}`,
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "250px",
                  height: "250px",
                  border: "1px solid lightgray",
                }}
              >
                <img
                  src={`data:image/svg+xml;base64,${item.flag}`}
                  alt={item.countryname}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="left"
                justifyContent="left"
                spacing={1}
                sx={{ textAlign: "left", width: "100%" }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {item.countryname}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {item.countrycode}
                </Typography>
                <Typography variant="body1">{item.countrydailcode} </Typography>

                {item.branch ? (
                  <Typography
                    variant="body1"
                    sx={{
                      color: item.branch === "Yes" ? "green" : "maroon",
                      fontWeight: "bold",
                    }}
                  >
                    {item.branch === "Yes"
                      ? "Branch Available"
                      : "Branch Not Available"}
                  </Typography>
                ) : null}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  startIcon={<IconEdit />}
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  startIcon={<IconDelete />}
                  sx={{ bgcolor: "red" }}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default Countries;
