import { FiArrowDownRight } from "react-icons/fi";
import {
  IconAnalytics,
  IconCareers,
  IconDownArrow,
  IconEnquiries,
  IconLAKCS,
  IconUpArrow,
  IconVisitors,
  IconHash,
  IconDot,
} from "../themes/Icons";

export const SidebarData = [
  {
    id: 1,
    icon: <IconAnalytics />,
    title: "Home",
    path: "/admin/home",
    roles: ["0", "1", "2", "3", "4"],
  },

  {
    id: 2,
    icon: <IconVisitors />,
    title: "Our Team",
    path: "/admin/team",
  },
  {
    id: 3,
    icon: <IconEnquiries />,
    title: "Enquiries",
    path: "/admin/enquiries",
  },
  {
    id: 4,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Website",
    submenuid: 4,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        subnavitemid: 41,
        title: "Life @ KTECH",
        path: "/admin/website/life-at-ktech",
      },

      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 42,
        title: "Leadership",
        path: "/admin/website/leadership",
      },
      {
        id: 3,
        icon: <IconDot />,
        subnavitemid: 43,
        title: "Banners",
        path: "/admin/website/banners",
      },
      {
        id: 4,
        icon: <IconDot />,
        subnavitemid: 44,
        title: "Web Pictures",
        path: "/admin/website/web-pictures",
      },

      {
        id: 5,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Logos",
        path: "/admin/website/logos",
      },

      {
        id: 6,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Global Foot Prints",
        path: "/admin/website/globalfootprints",
      },
      {
        id: 7,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Countries",
        path: "/admin/website/countries",
      },
      {
        id: 8,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Icons",
        path: "/admin/website/pageicons",
      },
      {
        id: 9,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Industries",
        path: "/admin/website/industries",
      },
    ],
  },

  {
    id: 5,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Careers",
    submenuid: 5,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        title: "Job Positions",
        subnavitemid: 51,
        path: "/admin/careers/job-positions",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 52,
        title: "Applications",
        path: "/admin/careers/applications",
      },

      {
        id: 3,
        icon: <IconDot />,
        subnavitemid: 53,
        title: "Employees Reviews",
        path: "/admin/careers/employees-reviews",
      },
    ],
  },

  {
    id: 6,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Events",
    submenuid: 6,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        title: "Events List",
        subnavitemid: 61,
        path: "/admin/events/list",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 62,
        title: "Events Registrations",
        path: "/admin/events/registrations",
      },

      // {
      //   id: 3,
      //   icon: <IconDot />,
      //   subnavitemid: 63,
      //   title: "Employees Reviews",
      //   path: "/admin/careers/employees-reviews",
      // },
    ],
  },

  // {
  //   id: 5,
  //   icon: <IconLAKCS />,
  //   title: "Webinars",
  //   iconopen: <IconUpArrow />,
  //   iconclose: <IconDownArrow />,
  //   submenuid: 5,
  //   submenu: [
  //     {
  //       id: 1,
  //       icon: <IconDot />,
  //       subnavitemid: 51,
  //       title: "Webinars List",
  //       path: "/admin/webinars/list",
  //     },
  //     {
  //       id: 2,
  //       icon: <IconDot />,
  //       subnavitemid: 52,
  //       title: "Registrations",
  //       path: "/admin/webinars/registrations",
  //     },
  //   ],
  // },

  {
    id: 9,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Feedbacks",
    submenuid: 9,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        subnavitemid: 91,
        title: "Monthly Feedbacks",
        path: "/admin/feedbacks/monthly-feedbacks",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 92,
        title: "Testimonials",
        path: "/admin/feedbacks/testimonials",
      },
    ],
  },
];

// ------------------------------------------------------------- Main Website

export const HRNavigation = [
  {
    id: 1,
    icon: <IconAnalytics />,
    title: "Home",
    path: "/admin/home",
  },

  {
    id: 2,
    icon: <IconVisitors />,
    title: "Our Team",
    path: "/admin/team",
  },

  {
    id: 3,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Careers",
    submenuid: 3,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        title: "Job Positions",
        subnavitemid: 31,
        path: "/admin/careers/job-positions",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 32,
        title: "Applications",
        path: "/admin/careers/applications",
      },

      {
        id: 3,
        icon: <IconDot />,
        subnavitemid: 33,
        title: "Employees Reviews",
        path: "/admin/careers/employees-reviews",
      },
    ],
  },

  {
    id: 4,
    icon: <IconLAKCS />,
    title: "Leadership",
    path: "/admin/leadership",
  },

  {
    id: 5,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Feedbacks",
    submenuid: 5,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        subnavitemid: 51,
        title: "Monthly Feedbacks",
        path: "/admin/feedbacks/monthly-feedbacks",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 52,
        title: "Testimonials",
        path: "/admin/feedbacks/testimonials",
      },
    ],
  },
];

export const SalesNavigations = [
  {
    id: 1,
    icon: <IconAnalytics />,
    title: "Home",
    path: "/admin/home",
  },

  {
    id: 2,
    icon: <IconEnquiries />,
    title: "Enquiries",
    path: "/admin/enquiries",
  },
  {
    id: 3,
    icon: <IconLAKCS />,
    title: "Webinars",
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    submenuid: 3,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        subnavitemid: 31,
        title: "Webinars List",
        path: "/admin/webinars/list",
      },
      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 32,
        title: "Registrations",
        path: "/admin/webinars/registrations",
      },
    ],
  },
];

export const PreSalesNavigations = [
  {
    id: 1,
    icon: <IconAnalytics />,
    title: "Home",
    path: "/admin/home",
  },

  {
    id: 2,
    icon: <IconEnquiries />,
    title: "Enquiries",
    path: "/admin/enquiries",
  },
  // {
  //   id: 3,
  //   icon: <IconLAKCS />,
  //   title: "Webinars",
  //   iconopen: <IconUpArrow />,
  //   iconclose: <IconDownArrow />,
  //   submenuid: 7,
  //   submenu: [
  //     {
  //       id: 1,
  //       icon: <IconDot />,
  //       subnavitemid: 31,
  //       title: "Webinars List",
  //       path: "/admin/webinars/list",
  //     },
  //     {
  //       id: 2,
  //       icon: <IconDot />,
  //       subnavitemid: 32,
  //       title: "Registrations",
  //       path: "/admin/webinars/registrations",
  //     },
  //   ],
  // },
];

export const DigitalMarketing = [
  {
    id: 1,
    icon: <IconAnalytics />,
    title: "Home",
    path: "/admin/home",
  },

  {
    id: 2,
    icon: <IconEnquiries />,
    title: "Enquiries",
    path: "/admin/enquiries",
  },

  {
    id: 3,
    icon: <IconCareers />,
    iconopen: <IconUpArrow />,
    iconclose: <IconDownArrow />,
    title: "Website",
    submenuid: 3,
    submenu: [
      {
        id: 1,
        icon: <IconDot />,
        subnavitemid: 41,
        title: "Life @ KTECH",
        path: "/admin/website/life-at-ktech",
      },

      {
        id: 2,
        icon: <IconDot />,
        subnavitemid: 42,
        title: "Leadership",
        path: "/admin/website/leadership",
      },
      {
        id: 3,
        icon: <IconDot />,
        subnavitemid: 43,
        title: "Banners",
        path: "/admin/website/banners",
      },
      {
        id: 4,
        icon: <IconDot />,
        subnavitemid: 44,
        title: "Web Pictures",
        path: "/admin/website/web-pictures",
      },

      {
        id: 5,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Logos",
        path: "/admin/website/logos",
      },

      {
        id: 6,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Global Foot Prints",
        path: "/admin/website/globalfootprints",
      },
      {
        id: 7,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Countries",
        path: "/admin/website/countries",
      },
      {
        id: 8,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Icons",
        path: "/admin/website/pageicons",
      },
      {
        id: 9,
        icon: <IconDot />,
        subnavitemid: 45,
        title: "Industries",
        path: "/admin/website/indutries",
      },
    ],
  },
  // {
  //   id: 6,
  //   icon: <IconLAKCS />,
  //   title: "Webinars",
  //   iconopen: <IconUpArrow />,
  //   iconclose: <IconDownArrow />,
  //   submenuid: 6,
  //   submenu: [
  //     {
  //       id: 1,
  //       icon: <IconDot />,
  //       subnavitemid: 61,
  //       title: "Webinars List",
  //       path: "/admin/webinars/list",
  //     },
  //     {
  //       id: 2,
  //       icon: <IconDot />,
  //       subnavitemid: 62,
  //       title: "Registrations",
  //       path: "/admin/webinars/registrations",
  //     },
  //   ],
  // },
];
