import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  IconAdd,
  IconClose,
  IconDelete,
  IconEdit,
  IconRightArrow,
} from "../../../themes/Icons";
import { EditPosition, createPosition } from "../../../api/Careers";
import { Domains, depts } from "../../../mock/FormFields";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../auth/Authentication";
import Page from "../../common/Page";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector, useDispatch } from "react-redux";
import { JobData } from "../../../redux/Actions";
import { DateTimePicker } from "@mui/x-date-pickers";
import { addEvent, updateEvent } from "../../../api/events/Eventslist";

const schema = yup.object({
  eventname: yup.string(),
  dept: yup.string(),
  eventtype: yup.string(),
  eventdateandtime: yup.string(),
  eventimage: yup.mixed(),
  domain: yup.array(),
  urllink: yup.string(),
  description: yup.string(),
  speaker: yup.array(),
  duration: yup.string(),
});

function CreateEvent() {
  const { user } = useAuth();
  const token = user === null ? null : user.accesstoken;
  const { pathname } = useLocation();
  const paths = pathname.split("/")[3];
  const bgcondition = paths === "create" || paths === "edit";
  const selecteddata = useSelector((state) => state.events.eventsdata);
  const SelObject = paths === "create" ? {} : selecteddata;
  const { enqueueSnackbar } = useSnackbar();

  console.log(SelObject, "Edit Object");

  const [base64, setbase64] = useState(null);
  const [editImage, setEditImage] = useState(false);

  const defaultValues = useMemo(
    () => ({
      eventname: SelObject.eventname || "",
      dept: SelObject.dept || "",
      eventtype: SelObject.eventtype || "",
      eventdateandtime: SelObject.eventdateandtime || null,
      eventimage: SelObject.eventimage || null,
      duration: SelObject.duration || 0,
      domain: SelObject.domain || [],
      urllink: SelObject.url || "",
      description: SelObject.eventdescription || "",
      speaker: SelObject.speaker || [{ did: 1, employee: "", email: "" }],
    }),
    [SelObject]
  );

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/admin/events/list");
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject, reset, defaultValues]);

  const handleEditImage = () => {
    setEditImage(true);
  };

  const Base64ToBinaryImage = (base64) => {
    const base64String = base64.substring(base64.indexOf(",") + 1);
    const binaryString = window.atob(base64String);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: "image/jpeg" });
    return blob;
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "speaker",
  });

  const selectedId = SelObject.id;
  let editPic = SelObject.eventimage;

  const onSubmit = (data) => {
    // Submission logic here
    console.log(data);
    console.log(data);
    if (paths === "edit") {
      const binaryfile = editImage
        ? data.eventimage
        : Base64ToBinaryImage(editPic);

      console.log(binaryfile);
      updateEvent(selectedId, data, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;

          // UpdatedRow(res.data.response);
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
          // enqueueSnackbar(err.data.message, { variant: "error" });
        });
    } else {
      addEvent(data, token)
        // dispatch(JobData(data))
        .then((res) => {
          // console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
          // enqueueSnackbar(err.data.message, { variant: "error" });
        });
    }
  };

  const handleResetThumbnail = () => {
    setbase64(null);
    editPic = "";
  };

  return (
    <DashboardMainContainer
      bgcolorcondition={bgcondition}
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Page title={paths === "edit" ? "Update Event" : "Create Event"} />

      <Grid
        component="form"
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "20px",
          width: "80%",
          mt: "10px",
          mb: "10px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "450px" }}
          spacing={1}
        >
          {base64 || (editPic && !editImage) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "400px",
                width: "auto",
                objectFit: "contain",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              <img
                src={`data:image/svg+xml;base64,${base64 || editPic}`}
                alt="Kapil Technologies Event Flyer"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
              {editPic && !base64 && (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              )}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "400px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
              htmlFor="pic"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  p: "10px",
                  fontSize: "20px",
                  "&:hover": {
                    bgcolor: "primary.main",
                    color: "white",
                  },
                }}
                onClick={handleResetThumbnail}
              >
                <IconClose />
              </Stack>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture!
              </Typography>

              <Controller
                name="eventimage"
                control={control}
                render={({ field }) => (
                  <input
                    id="pic"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        const cleanResume = base64.substring(
                          "data:image/svg+xml;base64,".length
                        );
                        setbase64(cleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="thumbnail"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <TextField
            label="Event Type"
            fullWidth
            autoComplete="off"
            error={Boolean(errors.eventtype)}
            helperText={errors.eventtype?.message}
            {...register("eventtype")}
          />
          <TextField
            label="Event Name"
            fullWidth
            autoComplete="off"
            error={Boolean(errors.eventname)}
            helperText={errors.eventname?.message}
            {...register("eventname")}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Controller
            name="eventdateandtime"
            control={control}
            render={({ field }) => (
              <DateTimePicker
                sx={{ width: "100%" }}
                label="Event Date & Time"
                value={field.value}
                onChange={field.onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(errors.eventdateandtime)}
                    helperText={errors.eventdateandtime?.message}
                  />
                )}
              />
            )}
          />
          <TextField
            label="Duration"
            fullWidth
            autoComplete="off"
            error={Boolean(errors.duration)}
            helperText={errors.duration?.message}
            {...register("duration")}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Controller
            name="dept"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  fullWidth
                  value={
                    value
                      ? depts.find((option) => {
                          return value == option.label;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  options={depts}
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.label : null);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Department" />
                  )}
                />
              );
            }}
          />

          <Controller
            name="domain"
            control={control}
            render={({ field }) => (
              <Autocomplete
                multiple
                fullWidth
                value={
                  field.value
                    ? field.value.map((v) =>
                        Domains.find((c) => `${c.domainvalue}` === v)
                      )
                    : []
                }
                getOptionLabel={(option) => `${option.domainname}`}
                options={Domains}
                onChange={(e, newValue) => {
                  const selectedValues = newValue.map(
                    (v) => `${v.domainvalue}`
                  );
                  field.onChange(selectedValues);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Domain" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.domainname}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            )}
          />
        </Stack>

        <TextField
          label="URL"
          fullWidth
          autoComplete="off"
          error={Boolean(errors.urllink)}
          helperText={errors.urllink?.message}
          {...register("urllink")}
        />

        <TextField
          label="Description"
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          autoComplete="off"
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          {...register("description")}
        />

        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {fields.map((field, index) => (
            <Stack
              direction="row"
              alignItems="center"
              key={field.did}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Typography variant="body1">{field.did}.</Typography>
              <TextField
                label={`Speaker Name`}
                sx={{ width: "80%" }}
                autoComplete="off"
                error={Boolean(errors?.speaker?.[index]?.employee)}
                helperText={errors?.speaker?.[index]?.employee?.message}
                {...register(`speaker.${index}.employee`)}
              />

              <TextField
                label={`Speaker Email`}
                sx={{ width: "80%" }}
                autoComplete="off"
                error={Boolean(errors?.speaker?.[index]?.email)}
                helperText={errors?.speaker?.[index]?.email?.message}
                {...register(`speaker.${index}.email`)}
              />

              <Button
                // startIcon={<IconDelete />}
                variant="outlined"
                color="error"
                onClick={() => remove(index)}
                sx={{ p: 1 }}
              >
                Delete
              </Button>
            </Stack>
          ))}
          <Button
            startIcon={<IconAdd />}
            variant="outlined"
            onClick={() =>
              append({ did: fields.length + 1, employee: "", email: "" })
            }
          >
            Add Speaker
          </Button>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ width: "100%", mt: "10px" }}
          spacing={2}
        >
          <Button
            startIcon={<IconRightArrow />}
            variant="contained"
            color="primary"
            type="submit"
          >
            {paths === "edit" ? "Update Event" : "Create Event"}
          </Button>
          <Link to="/admin/events/list" style={{ textDecoration: "none" }}>
            <Button
              startIcon={<IconClose />}
              color="error"
              variant="outlined"
              sx={{ mr: "10px" }}
            >
              Cancel
            </Button>
          </Link>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateEvent;
