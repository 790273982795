import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import KtechLogo from "../../assets/Ktechlogo2.png";

const LogoComponent = ({ textcolor }) => {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      justifyContent="center"
      sx={{
        textTransform: "uppercase",
        width: isMobile || isTab ? "120px" : "150px",
        height: "auto",
        mt: isMobile || isTab ? null : "15px",
        bgcolor:'white',p:"10px"
      }}
    >
      <img
        src={KtechLogo}
        alt="Kapil technologies logo"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    </Stack>
  );
};

export default LogoComponent;
