import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function DeleteValidation({ open, onConfirm, onCancel }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openDialog, setOpenDialog] = useState(open);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = () => {
    onConfirm();
    handleClose();
  };

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Are you Sure ?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{ bgcolor: "red" }}
          >
            Delete
          </Button>

          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteValidation;
