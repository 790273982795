import React, { Fragment } from 'react'
import Page from '../../../components/common/Page';

function MFeedbacks() {
  return (
    <Fragment>
      <Page title="Monthly Feedbacks" />
    </Fragment>
  );
}

export default MFeedbacks