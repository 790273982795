import React from "react";
import { DashboardMainContainer } from "../dashboards/Analytics";
import Page from "../../components/common/Page";
import { Stack, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChangePassword from "../../components/Forms/auth/ChangePassword";

const TabData = [
  {
    id: 1,
    tabname: "Account Details",
    tabvalue: "1",
  },

  {
    id: 2,
    tabname: "Change Password",
    tabvalue: "2",
  },
];

function Settings() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DashboardMainContainer>
      <Page title="Settings" />
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="start"
        sx={{ width: "100%" }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "80%", border: "0.5px solid blue" }}
        >
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="white"
              variant="scrollable"
              // textColor="#CB333B"
              sx={{
                "& button": {
                  // borderRadius: 2,
                  fontSize: "18px",
                  margin: "5px",
                },

                ".Mui-selected": {
                  color: "white",
                  // borderBottom: "3px solid #CB333B",
                  // borderRadius: 2,
                  fontSize: "18px",
                },
              }}
            >
              {TabData.map((item) => (
                <Tab
                  key={item.id}
                  label={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {/* <IconBase>{item.tabicon}</IconBase> */}
                      <Typography variant="body1">{item.tabname}</Typography>
                    </Stack>
                  }
                  value={item.tabvalue}
                  sx={{
                    textTransform: "capitalize",
                  }}
                />
              ))}
            </TabList>
            <TabPanel value="1">Account Details</TabPanel>
            <TabPanel value="2" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <ChangePassword />
              </Stack>
            </TabPanel>
          </TabContext>
        </Stack>
      </Stack>
    </DashboardMainContainer>
  );
}

export default Settings;
