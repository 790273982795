import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  lifektechObject: null,
  isError: null,
};

const slice = createSlice({
  name: "lifeatktech",
  initialState,
  reducers: {
    ktechObject(state, action) {
      const response = action.payload;
      state.lifektechObject = response;
      state.isError = null;
    },
  },
});

export const { ktechObject } = slice.actions;

export default slice.reducer;
