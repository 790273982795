import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  logosObject: null,
  isError: null,
};

const slice = createSlice({
  name: "ktechLogos",
  initialState,
  reducers: {
    logoObject(state, action) {
      const response = action.payload;
      state.logosObject = response;
      state.isError = null;
    },
    logoType(state, action) {
      const response = action.payload;
      state.logoformat = response;
      state.isError = null;
    },
  },
});

export const { logoObject, logoType } = slice.actions;

export default slice.reducer;
