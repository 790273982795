import React, { Fragment, useEffect, useMemo, useState } from "react";
import { DashboardMainContainer } from "../../../pages/dashboards/Analytics";
import Page from "../../common/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAuth } from "../../../auth/Authentication";
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Domains } from "../../../mock/FormFields";
import { ErrorMessage } from "@hookform/error-message";
import { createMember, updateMember } from "../../../api/website/Leadership";
import { memberObject } from "../../../redux/slices/website/Management";
import { IconEdit } from "../../../themes/Icons";

const schema = yup.object({
  empname: yup.string().required("Employee Name is Required"),
  subtitle: yup.string().required("Subtitle is Required"),
  designation: yup.string().required("Employee Designation is Required"),
  member: yup.mixed().required("Employee Picture is Required"),
  serial: yup.string(),
  domain: yup
    .array()
    .min(1, "At least one domain must be selected")
    .required("Domain is Required"),
  url: yup.string(),
});

function CreateManagement() {
  // -----------------------------------------  Base64tobinaryfile
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [base64, setbase64] = useState(null);

  const [editImage, setEditImage] = useState(false);

  const [imageType, setImageType] = useState("");

  const paths = pathname.split("/")[4];
  const bgcondition = paths === "create" || paths === "edit";
  const token = user === null ? null : user.accesstoken;
  const selecteddata = useSelector((state) => state.leadership.memberdetails);
  const SelObject = paths === "create" ? {} : selecteddata;

  const handleNavigate = () => {
    Navigate("/admin/website/leadership");
  };

  const handleEditImage = () => {
    setEditImage(true);
  };

  const defaultValues = useMemo(
    () => ({
      empname: SelObject.name || "",
      designation: SelObject.designation || "",
      member: SelObject.pic || null,
      domain: SelObject.domain || [],
      serial: SelObject.serial || "",
      url: SelObject.linkedinurl || "",
      subtitle: SelObject.subtitle || "",
    }),
    [SelObject]
  );
  // -----------------------------------------  Base64tobinaryfile

  const Base64ToBinaryImage = (base64String, filetype) => {
    // Extract the base64 part of the string

    const base64 = `data:image/${filetype};base64,${base64String}`;

    const base64Data = base64.split(",")[1];

    // Convert the base64 data to a binary string
    const binaryString = window.atob(base64Data);

    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: `image/${filetype}` });

    // Create a URL for the Blob
    return blob;
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (paths === "edit" && SelObject) {
      reset(defaultValues);
    }
  }, [SelObject]);

  const selectedId = SelObject.id;

  const EditPic = SelObject.pic;

  const filetype = SelObject.fileformat;

  const onSubmit = (data) => {
    const Requestdata = {
      empname: data.empname,
      empid: data.empid,
      designation: data.designation,
      member: data.member,
      domain: data.domain,
      serial: data.serial,
      fileformat: data.fileformat,
      linkedinurl: data.url,
      subtitle: data.subtitle,
    };
    if (paths === "edit") {
      const binaryfile = editImage
        ? data.member
        : Base64ToBinaryImage(EditPic, filetype);
      updateMember(selectedId, Requestdata, binaryfile, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });

            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    } else {
      // console.log("create");
      // console.log(data);
      createMember(Requestdata, token)
        .then((res) => {
          console.log(res);
          const status = res.data.success;
          if (status === true) {
            enqueueSnackbar(res.data.message, { variant: "success" });
            // console.log(newBranch);
            handleNavigate();
          } else enqueueSnackbar(res.data.message, { variant: "error" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.message, { variant: "error" });
        });
    }
  };
  return (
    <DashboardMainContainer
      bgcolorcondition={bgcondition}
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Page
        title={paths === "create" ? "Add Management" : "Update Management"}
      />
      <Grid
        sx={{
          width: "90%",

          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        columnGap={2}
        rowGap={2}
        container
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{ width: "100%", height: "400px" }}
          component={Grid}
          item
          xs={12}
          md={4}
          spacing={1}
        >
          {base64 || (EditPic && editImage !== true) ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
              sx={{
                height: "320px",
                width: "250px",
                objectFit: "cover",
                border: "1px solid gray",
                display: "flex",
              }}
              id="pic"
            >
              <img
                src={`data:image/${imageType};base64,${base64 || EditPic}`}
                style={{
                  objectPosition: "center center",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />

              {EditPic && !base64 ? (
                <Button
                  startIcon={<IconEdit />}
                  variant="contained"
                  onClick={handleEditImage}
                >
                  Edit Image
                </Button>
              ) : null}
            </Stack>
          ) : (
            <label
              style={{
                border: "1px solid gray",
                height: "300px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              htmlFor="pic"
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Select your Picture !
              </Typography>

              <Controller
                name="member"
                control={control}
                render={({ field }) => (
                  <input
                    id="pic"
                    type="file"
                    accept=".svg, .png, .jpg, .jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const fileformat = file.type;
                      console.log(fileformat);
                      setImageType(fileformat);
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64 = reader.result;
                        const typeIndex =
                          base64.indexOf("base64,") + "base64,".length;
                        const CleanResume = base64.substring(typeIndex);
                        setbase64(CleanResume);
                        field.onChange(e.target.files[0]);
                      };
                    }}
                  />
                )}
              />
            </label>
          )}

          <ErrorMessage
            errors={errors}
            name="member"
            render={({ message }) => (
              <Typography variant="body2" sx={{ color: "red" }}>
                {message}
              </Typography>
            )}
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%", height: "auto" }}
          component={Grid}
          item
          xs={12}
          md={7.5}
        >
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("empname")}
              label="Employee Name"
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="empname"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("designation")}
              label="Designation"
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="designation"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("subtitle")}
              label="Subtitle"
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="subtitle"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("serial")}
              label="Serial"
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="serial"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <TextField
              {...register("url")}
              label="LinkedIn URL"
              autoComplete="off"
            />
            <ErrorMessage
              errors={errors}
              name="url"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction="column" alignItems="left" sx={{ width: "100%" }}>
            <Controller
              name="domain"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <Autocomplete
                    multiple
                    fullWidth
                    value={
                      value
                        ? value.map((v) =>
                            Domains.find((c) => `${c.domainvalue}` === v)
                          )
                        : []
                    }
                    getOptionLabel={(option) => `${option.domainname}`}
                    options={Domains}
                    onChange={(e, newValue) => {
                      const selectedValues = newValue.map(
                        (v) => `${v.domainvalue}`
                      );
                      onChange(selectedValues);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Display in" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={`${option.domainname}`}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const newValues = [...value];
                            newValues.splice(index, 1);
                            onChange(newValues.map((v) => `${v.domainvalue}`));
                          }}
                        />
                      ))
                    }
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name="domain"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="right"
            sx={{ width: "100%" }}
          >
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </DashboardMainContainer>
  );
}

export default CreateManagement;
