import React, { Fragment, useEffect, useState } from "react";
import Page from "../../../components/common/Page";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMainContainer } from "../Analytics";
import { Button, Card, Grid, Stack, Typography, useTheme } from "@mui/material";
import { getGFP } from "../../../api/website/GlobalBranchesAPI";
import { useSnackbar } from "notistack";
import { IconDelete, IconEdit } from "../../../themes/Icons";
import DeleteValidation from "../../../components/common/DeleteValidation";
import { branchobject } from "../../../redux/slices/website/GlobalFootPrints";
import { useDispatch } from "react-redux";

function GlobalBranches() {
  const Navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [Branches, setBranches] = useState([]);
  const dispatch = useDispatch();

  const [ShowValidation, setShowValidation] = useState(false);

  useEffect(() => {
    getGFP()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        // UpdatedRow(res.data.response);
        if (status === true) {
          // enqueueSnackbar(res.data.message, { variant: "success" });
          setBranches(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
        // enqueueSnackbar(err.data.message, { variant: "error" });
      });
  }, []);

  const handleNavigate = () => {
    Navigate("/admin/website/globalfootprints/create");
  };

  const handleEdit = (item) => {
    dispatch(branchobject(item));
    Navigate("/admin/website/globalfootprints/edit");
  };

  // ------------------------------------  DELETE Operation

  const handleShowDeleteValidation = (id) => {
    setShowValidation(true);
  };

  const handleDelete = (id) => {
    // Perform delete action
    setShowValidation(false);
  };

  const handleCancel = () => {
    setShowValidation(false);
  };

  return (
    <DashboardMainContainer>
      <Page
        title="Global Foot Prints"
        handleFunction={handleNavigate}
        buttonname="Add New Branch"
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "95%", py: 1, marginBottom: "20px", px: 2 }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {Branches.length === 0 ? (
          <Typography>No Global Branches Found !</Typography>
        ) : (
          Branches.map((item) => (
            <Card
              item
              key={item.id}
              spacing={1}
              component={Grid}
              // xs={12}
              // md={3.5}
              sx={{
                width: { xs: "400px", sm: "350px", md: "400px" },
                height: "400px",
                // border: `1px solid ${theme.palette.primary.main}`,
                p: 2,
                display: "flex",
                alignItems: "left",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {item.country}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {item.companyname}
              </Typography>
              {item.careof ? (
                <Typography variant="body2">{item.careof}</Typography>
              ) : null}
              {item.add1 ? (
                <Typography variant="body2">{item.add1}</Typography>
              ) : null}
              {item.add2 ? (
                <Typography variant="body2">{item.add2}</Typography>
              ) : null}
              {item.city ? (
                <Typography variant="body2">{item.city}</Typography>
              ) : null}
              {item.state ? (
                <Typography variant="body2">{item.state}</Typography>
              ) : null}
              {item.zipcode ? (
                <Typography variant="body2">{item.zipcode}</Typography>
              ) : null}
              {item.add2 ? (
                <Typography
                  variant="body1"
                  component={Link}
                  to={`mailto:${item.contactemail}`}
                  sx={{ textDecoration: "none", color: "black" }}
                >
                  {item.contactemail}
                </Typography>
              ) : null}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  startIcon={<IconEdit />}
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  startIcon={<IconDelete />}
                  sx={{ bgcolor: "red" }}
                  onClick={() => handleShowDeleteValidation(item.id)}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
      <DeleteValidation
        open={ShowValidation}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
    </DashboardMainContainer>
  );
}

export default GlobalBranches;
