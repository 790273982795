import { APIURL } from "../config";
import axios from "axios";
import { apilinks } from "./APILink";

// console.log(APIURL);

export const createPosition = async (data, token) => {
  const response = await axios.post(
    `${APIURL}positions/addposition`,
    JSON.stringify({
      jobtitle: data.jobtitle,
      category: data.dept,
      jobtype: data.jobtype,
      duration: data.duration,
      experience: data.experience,
      jobmode: data.jobmode,
      location: data.location,
      description: data.description,
      totalApplied: data.totalapplied,
      selected: data.selected,
      rejected: data.rejected,
      status: "active",
      timezone: data.timezone,
      emailforprofiles: data.emailforprofiles,
      domain: data.domain,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getPositions = async (token) => {
  const response = await axios.get(apilinks.jobopenings.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const EditPosition = async (id, data, token) => {
  const response = await axios.patch(
    `${APIURL}positions/editposition/${id}`,
    JSON.stringify({
      jobtitle: data.jobtitle,
      category: data.dept,
      jobtype: data.jobtype,
      duration: data.duration,
      experience: data.experience,
      jobmode: data.jobmode,
      location: data.location,
      description: data.description,
      emailforprofiles: data.emailforprofiles,
      timezone: data.timezone,
      domain: data.domain,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const UpdateStatus = async (id, data, token) => {
  const response = await axios.put(
    `${APIURL}positions/status/${id}`,
    JSON.stringify({
      status: data.status,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const deletePositions = async (id, token) => {
  const response = await axios.delete(`${APIURL}deleteposition/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const updatePositionStatus = async (id, data, token) => {
  const response = await axios.put(
    `${APIURL}positions/status/${id}`,
    JSON.stringify({
      status: data.status,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

// ----------------------------------------------------------- APLLICATION

export const getApplications = async (token) => {
  const response = await axios.get(
    apilinks.applications.get,

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const getResume = async (appid, token) => {
  const response = await axios.get(`${APIURL}getResume/${appid}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const editApplicant = async (id, data, token) => {
  const response = await axios.put(
    `${APIURL}updateStatus/${id}`,
    JSON.stringify({
      status: data.status,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

export const getPositonStats = async (id) => {
  const response = await axios.get(`${APIURL}positionstats/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      // Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
