import React from "react";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const MainDiv = styled("footer")(({ theme, color }) => ({
  height: "auto",
  width: "100%",
  background: theme.palette.primary.main,
  color: "white",
  // position: "absolute",
  // bottom: 0,

  [theme.breakpoints.between("xs", "md")]: {
    // tab
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },
}));

function Footer() {
  return (
    <MainDiv>
      <Stack
        direction="column"
        alignItems="start"
        justifyContent="center"
        sx={{ p: 2 }}
      >
        Footer
      </Stack>
    </MainDiv>
  );
}

export default Footer;
