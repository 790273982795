import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { IconDelete, IconEdit } from "../../../themes/Icons";
import { getKtechImages } from "../../../api/website/LAKtech";
import { ktechObject } from "../../../redux/slices/website/LIfeatKtech";
import Page from "../../../components/common/Page";
import { DashboardMainContainer } from "../Analytics";

export const TextSection = ({ keydata, value }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  );
};

export const Domain = ({ keydata, value }) => {
  // console.log(value)
  const result = value.join(", "); // 'apple, banana, cherry'
  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {keydata}
      </Typography>
      <Typography variant="body1">:</Typography>
      <Typography variant="body1">{result}</Typography>
    </Stack>
  );
};

function LifeatKtech() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [logos, setLogos] = useState([]);

  const handleNavigate = () => {
    navigate("/admin/website/life-at-ktech/create");
  };

  const handleEdit = (item) => {
    dispatch(ktechObject(item));
    navigate("/admin/website/life-at-ktech/edit");
  };

  useEffect(() => {
    getKtechImages()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        if (status === true) {
          setLogos(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, []);

  return (
    <DashboardMainContainer direction="column" alignItems="center">
      <Page
        title="Life @KTECH"
        handleFunction={handleNavigate}
        buttonname="Add New Image"
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "90%", py: 2, marginBottom: "20px", height: "auto" }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {logos.length === 0 ? (
          <Typography>No Images Found For Life @ KTECH !</Typography>
        ) : (
          logos.map((item) => (
            <Card
              key={item.id}
              spacing={1}
              component={Grid}
              sx={{
                width: "100%",
                height: { xs: "auto", sm: "250px", md: "300px" },
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "500px",
                  height: "auto",
                  border: "1px solid lightgray",
                }}
              >
                <img
                  src={`data:image/svg+xml;base64,${item.eventimage}`}
                  alt={item.eventname}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
                sx={{ width: { xs: "90%", sm: "90%", md: "60%" } }}
              >
                <TextSection keydata="Image Name" value={item.eventname} />
                <TextSection keydata="Show Image" value={item.showimage} />
                <Domain keydata="Displayed in" value={item.domain} />

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Button
                    variant="contained"
                    startIcon={<IconEdit />}
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<IconDelete />}
                    sx={{ bgcolor: "red" }}
                  >
                    Delete
                  </Button>
                </Stack>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default LifeatKtech;
