import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  user: null,
  isAuthenticated: false,
  isError: null,
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login(state, action) {
      const response = action.payload;
      state.user = response;
      state.isAuthenticated = true;
      state.isError = null;
    },
    logout(state, action) {
      const response = action.payload;
      state.user = null;
      state.isAuthenticated = false;
      state.isError = response;
    },
  },
});

export const LoginActions = slice.actions;

export const LogoutActions = slice.actions;

export default slice.reducer;


