import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const addIcon = async (data, token) => {
  const formdata = new FormData();
  formdata.append("iconname", data.iconname);
  formdata.append("icontype", data.icontype);
  formdata.append("icon", data.icon);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("pagepath", JSON.stringify(data.pagepath));

  const response = await axios.post(apilinks.icons.add, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multimedia/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  GET

export const getIcons = async () => {
  const response = await axios.get(apilinks.icons.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateIcon = async (id, data, binaryfile, token) => {
  const formdata = new FormData();
  // console.log(data.member)
  formdata.append("iconname", data.iconname);
  formdata.append("icontype", data.icontype); 
  formdata.append("icon", binaryfile);
  formdata.append("domain", JSON.stringify(data.domain));
  formdata.append("pagepath", JSON.stringify(data.pagepath));

  const response = await axios.patch(`${apilinks.icons.update}/${id}`, formdata, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/formdata",
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// ---------------------------------------------------  DELETE

export const deleteIcon = async (id, token) => {
  const response = await axios.delete(`${apilinks.icons.delete}/${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
