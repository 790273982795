import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  industriesdata: null,
  isError: null,
};

const slice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    indutriesObject(state, action) {
      const response = action.payload;
      state.industriesdata = response;
      state.isError = null;
    },
  },
});

export const { indutriesObject } = slice.actions;

export default slice.reducer;
