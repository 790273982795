import { CareerActions } from "./slices/Careers";
import { LoginActions, LogoutActions, } from "./slices/Login";
import { RegisterAction } from "./slices/Register";
// import { WebinarDataActions, WebinarStatusActions } from "./slices/Webinars";
import { dispatch } from "./store";

export const LoginData = (data) => {
  const response = dispatch(LoginActions.login(data));
  return response;
};

export const Logout = (data) => {
  const response = dispatch(LogoutActions.logout(data));
  return response;
};

export const RegisterData = (data) => {
  const response = dispatch(RegisterAction.register(data));
  return response;
};

// -----------------------------------------------------------  Carrers

export const JobData = (data) => {
  const response = dispatch(CareerActions.jobopening(data));
  return response;
};


// -----------------------------------------------------------  Webinars

