import { Box, Container, Grid, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import LogoComponent from "../../components/common/LogoComponent";
import RegisterForm from "../../components/Forms/auth/RegisterForm";
import LoginForm from "../../components/Forms/auth/LoginForm";

export const MainContainer = styled(Grid)(({ theme, color }) => ({
  width: "100%",
  height: "100%",
  background: theme.palette.secondary.main,
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const GridContainers = styled(Grid)(({ theme, color, bgcolor }) => ({
  // border:
  //   bgcolor === "grid2" ? `1px solid ${theme.palette.primary.main}` : null,
  background:
    bgcolor === "grid2"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
  padding: "10px",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: 2,
  flexDirection: "column",
  color: bgcolor === "grid2" ? theme.palette.primary.main : null,
}));

function Login() {
  // --------------------------------------------------------------  Media Queries

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isMobiletoTab = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "md")
  );

  // --------------------------------------------------------------  Media Queries
  return (
    <MainContainer container>
      {!isMobiletoTab ? (
        <GridContainers item xs={6}>
          <LogoComponent />
        </GridContainers>
      ) : null}

      <GridContainers item xs={12} md={6} bgcolor="grid2">
        <LoginForm />
      </GridContainers>
    </MainContainer>
  );
}

export default Login;
