import React, { useEffect, useState } from "react";
import Page from "../../../components/common/Page";
import { DashboardMainContainer } from "../Analytics";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { getMembers } from "../../../api/website/Leadership";
import { useSnackbar } from "notistack";
import { IconDelete, IconEdit, IconLinkedin } from "../../../themes/Icons";
import { memberObject } from "../../../redux/slices/website/Management";
import { useDispatch } from "react-redux";

function Leadership() {
  const Navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);

  const handleNavigate = () => {
    Navigate("/admin/website/leadership/create");
  };

  const handleEdit = (item) => {
    dispatch(memberObject(item));
    Navigate("/admin/website/leadership/edit");
  };

  useEffect(() => {
    getMembers()
      .then((res) => {
        console.log(res);
        const status = res.data.success;
        // UpdatedRow(res.data.response);
        if (status === true) {
          // enqueueSnackbar(res.data.message, { variant: "success" });
          setMembers(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
        // enqueueSnackbar(err.data.message, { variant: "error" });
      });
  }, []);

  return (
    <DashboardMainContainer>
      <Page
        title="Leadership"
        handleFunction={handleNavigate}
        buttonname="Add New Management"
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: "100%", py: 2, marginBottom: "20px", height: "auto" }}
        component={Grid}
        container
        columnGap={2}
        rowGap={2}
      >
        {members.length === 0 ? (
          <Typography>No Members Found !</Typography>
        ) : (
          members.map((item) => (
            <Card
              item
              key={item.id}
              spacing={1}
              component={Grid}
              // xs={12}
              // md={3.5}
              sx={{
                width: { xs: "400px", sm: "350px", md: "300px" },
                height: "500px",
                // border: `1px solid ${theme.palette.primary.main}`,
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",

                gap: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "250px",
                  height: "250px",
                  border: "1px solid lightgray",
                  bgcolor: "white",
                }}
              >
                <img
                  src={`data:image/jpeg;base64,${item.pic}`}
                  alt={item.name}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="start"
                spacing={1}
                sx={{ textAlign: "left", width: "100%" }}
              >
                <Typography variant="body1">{item.serial}</Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {item.name}
                </Typography>
                <Typography variant="body1">{item.designation} </Typography>{" "}
                <Typography variant="body1">{item.subtitle} </Typography>
                {item.linkedinurl ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                    component={Link}
                    target="blank"
                    to={item.linkedinurl}
                    sx={{
                      textDecoration: "none",
                      color: "#0A66C2",
                      fontSize: "25px",
                    }}
                  >
                    <IconLinkedin />
                  </Stack>
                ) : null}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  startIcon={<IconEdit />}
                  onClick={() => handleEdit(item)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  startIcon={<IconDelete />}
                  sx={{ bgcolor: "red" }}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          ))
        )}
      </Stack>
    </DashboardMainContainer>
  );
}

export default Leadership;
