import React, { useEffect, useState } from "react";
import Page from "../../components/common/Page";
import {
  Box,
  MenuItem,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { getVisitors } from "../../api/Auth";
import { useSnackbar } from "notistack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Tabs1 } from "../../mock/AnalyticsMock";
import { Domains } from "../../mock/FormFields";

// Register necessary components for the chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const NavbarHeight = "60px";

export const DashboardMainContainer = styled(Stack)(
  ({ theme, bgcolorcondition }) => ({
    background:
      theme?.palette?.[bgcolorcondition ? "secondary" : "terinary"]?.main ||
      "defaultBackground",
    overflowY: "scroll",
    position: "absolute",
    height: "calc(100vh - 75px)",
    right: 0,
    width: "calc(100% - 300px)",
    top: "75px",
    [theme.breakpoints.between("xs", "md")]: {
      height: "calc(100vh - 60px)",
      width: "100%",
    },
  })
);

function Analytics() {
  const [domainData, setDomainData] = useState(null);
  const [pagedata, setPageData] = useState(null);
  const [value, setValue] = useState("1");
  const [countrydomain, setCountrydomain] = useState([]);
  const [selecteddomain, setSelectedDomain] = useState("kapiltech.com");

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const getQuarter = (date) => {
    const month = new Date(date).getMonth() + 1; // getMonth() is zero-based
    if (month <= 3) return "Q1";
    if (month <= 6) return "Q2";
    if (month <= 9) return "Q3";
    return "Q4";
  };

  useEffect(() => {
    getVisitors()
      .then((res) => {
        if (res.data.success) {
          const data = res.data.response;

          const domainCounts = data.reduce((acc, item) => {
            const normalizedDomain = item.domain.toLowerCase();
            acc[normalizedDomain] = (acc[normalizedDomain] || 0) + 1;
            return acc;
          }, {});

          const DomainData = {
            labels: Object.keys(domainCounts),
            datasets: [
              {
                label: "Domain Views",
                data: Object.values(domainCounts),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
            ],
          };

          setDomainData(DomainData);

          const pageDomainCount = data.reduce((acc, item) => {
            const key = `${item.page} (${item.domain})`;
            acc[key] = (acc[key] || 0) + 1;
            return acc;
          }, {});

          const backgroundColors = Object.keys(pageDomainCount).map(() => {
            return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)}, 0.2)`;
          });

          const borderColors = backgroundColors.map((color) =>
            color.replace("0.2", "1")
          );

          const PageDomainCount = {
            labels: Object.keys(pageDomainCount),
            datasets: [
              {
                label: "Page Views as per Domain",
                data: Object.values(pageDomainCount),
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
              },
            ],
          };

          setPageData(PageDomainCount);

          const countryDomainCounts = data
            .filter((item) => item.domain === selecteddomain)
            .reduce((acc, item) => {
              const key = item.country;
              acc[key] = (acc[key] || 0) + 1;
              return acc;
            }, {});

          const CountryDomainData = {
            labels: Object.keys(countryDomainCounts),
            datasets: [
              {
                label: `Domain Views by Country for ${selecteddomain}`,
                data: Object.values(countryDomainCounts),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
            ],
          };

          setCountrydomain(CountryDomainData);
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }, [enqueueSnackbar, selecteddomain]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardMainContainer theme={theme}>
      <Page title="Home" />
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="start"
        sx={{ width: "100%" }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="start"
          sx={{
            width: { xs: "100%", sm: "90%" },
            py: "10px",
            height: { xs: "auto", md: "450px" },
            bgcolor: "white",
          }}
        >
          <TabContext value={value} sx={{ width: "100%" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              textColor="#CB333B"
              sx={{
                "& button": {
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "16px",
                  },
                },
                "& button:hover": {
                  borderBottom: `3px solid ${theme.palette.primary.main}`,
                },
                ".Mui-selected": {
                  color: "#CB333B",
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "18px",
                  },
                },
              }}
            >
              {Tabs1.map((item) => (
                <Tab
                  label={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>{item.title}</Typography>
                    </Stack>
                  }
                  value={item.value}
                  sx={{
                    textTransform: "capitalize",
                  }}
                  key={item.id}
                />
              ))}
            </TabList>

            <TabPanel value="1" sx={{ width: "90%", height: "350px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "350px",
                  flexDirection: "column",
                  width: "100%",
                  border: "1px solid #d3e1ea",
                }}
              >
                {domainData && (
                  <Bar
                    data={domainData}
                    options={{
                      scales: {
                        x: {
                          beginAtZero: true,
                        },
                        indexAxis: "y",
                      },
                    }}
                  />
                )}
              </Box>
            </TabPanel>

            <TabPanel value="2" sx={{ width: "90%", height: "350px" }}>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-evenly"
                sx={{
                  width: "100%",
                  height: "350px",
                }}
              >
                <TextField
                  select
                  fullWidth
                  value={selecteddomain}
                  onChange={(e) => setSelectedDomain(e.target.value)}
                  defaultValue="kapiltech.com"
                >
                  {Domains.map((item) => (
                    <MenuItem key={item.id} value={item.domainvalue}>
                      <Typography variant="body1">{item.domainname}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid #d3e1ea",
                    width: "100%",
                  }}
                >
                  {countrydomain && (
                    <Bar
                      data={countrydomain}
                      options={{
                        scales: {
                          x: {
                            beginAtZero: true,
                          },
                          indexAxis: "y",
                        },
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </TabPanel>
          </TabContext>
        </Stack>
      </Stack>
    </DashboardMainContainer>
  );
}

export default Analytics;
