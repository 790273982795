import { apilinks } from "../APILink";
import axios from "axios";

// ---------------------------------------------------  CREATE

export const createBranch = async (data, token) => {
  const response = await axios.post(
    apilinks.globalfootprints.add,
    JSON.stringify({
      country: data.country,
      companyname: data.companyname,
      subtitle: data.subtitle,
      subtype: data.subtitletype,
      add1: data.add1,
      add2: data.add2,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
      email: data.email,
      contact: data.conatct,
      addrtype: data.addrtype,
      contactperson: data.contactperson,
      domain: data.domain,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// ---------------------------------------------------  GET

export const getGFP = async () => {
  const response = await axios.get(apilinks.globalfootprints.get, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/Json",
    },
  });

  return response;
};

// ---------------------------------------------------  UPDATE

export const updateGFP = async (id, token, data) => {
  console.log(data)
  const response = await axios.put(
    `${apilinks.globalfootprints.update}/${id}`,
    JSON.stringify({
      country: data.country,
      companyname: data.companyname,
      subtitle: data.subtitle,
      subtype: data.subtitletype,
      add1: data.add1,
      add2: data.add2,
      city: data.city,
      state: data.state,
      pincode: data.pincode,
      email: data.email,
      contact: data.conatct,
      addrtype: data.addrtype,
      contactperson: data.contactperson,
      domain: data.domain,
    }),
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

// ---------------------------------------------------  DELETE

export const deleteGFP = async (id, token) => {
  const response = await axios.delete(
    `${apilinks.globalfootprints.delete}/${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/Json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
