import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  webpdetails: null,
  isError: null,
};

const slice = createSlice({
  name: "webpictures",
  initialState,
  reducers: {
    wpicturesObject(state, action) {
      const response = action.payload;
      state.webpdetails = response;
      state.isError = null;
    },
  },
});

export const { wpicturesObject } = slice.actions;

export default slice.reducer;
